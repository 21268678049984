import { useEffect, useState } from "react";
import Endpoint from "./../../../../Utils/Endpoint";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
function Product_Detail_ProductImages(props) {
  const [sliderKey, setSliderKey] = useState(0);
  const { matchingVariant } = props;
  useEffect(() => {
    console.log(
      "xzXzX",
      props
    );
  }, []);

  useEffect(() => {
    // Update the key to force re-render when content changes
    setSliderKey((prevKey) => prevKey + 1);
  }, [matchingVariant]);




  return (
    matchingVariant != undefined ? (
      <>
        <div className="product-img text-center">
         
          <Slider key={sliderKey} {...settings}>
          {/* {props.MainImage && (
            <img
              src={`${Endpoint}/${props.MainImage && props.MainImage}`}
              className="img-fluid"
              alt="Product-Main-Image"
              style={{ height: "46vh", width: "100%" }}
            />
          )} */}

{
  matchingVariant.Images && matchingVariant.Images.length > 0 ? (
    matchingVariant.Images.map((img) => (
      <div>
        <img
          src={`${Endpoint}/${img.Large}`}
          className="img-fluid"
          alt="Product-Main-Image"
          style={{ height: "46vh", width: "100%" }}
        />
        {/* other content */}
      </div>
    ))
  ) : matchingVariant.Large ? (
    <div>
      <img
        src={`${Endpoint}/${matchingVariant.Large}`}
        className="img-fluid"
        alt="Product-Main-Image"
        style={{ height: "46vh", width: "100%" }}
      />
      {/* other content */}
    </div>
  ) : (
    <h1>No Data</h1>
  )
}




            {matchingVariant.video && (
              <div>
                <video
                  controls
                  style={{ height: "46vh", width: "100%", objectFit: "cover" }}
                >
                  <source
                    src={`${Endpoint}/${
                      matchingVariant.video && matchingVariant.video
                    }`}
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            )}
          </Slider>
        </div>
      </>
    ) : <>
       <span style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
       <img src="https://st.depositphotos.com/54433710/52516/v/450/depositphotos_525163566-stock-illustration-camera-outline-vector-icon-desig.jpg"  width={300} height={300} />
       <h5>Not available </h5>
       </span>
    </>
  );
}
export default Product_Detail_ProductImages;
