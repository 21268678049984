import { Link } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardHeader,
  Collapse,
  CardFooter,
} from "reactstrap";

import Swal from "sweetalert2";
import RatingStars from "../../../../Helpers/RatingStars";
import Endpoint from "../../../../Utils/Endpoint";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import BanglaBazarApi from "../../../Api/BanglaBazarApi";

function OrderProductOrder({ order }) {
  let history = useHistory();

  const [IsCollpase, setIsCollpased] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let GetPriceOrVariation = (indexedOrder, status) => {
    let currentOrder = indexedOrder;
    let basePrice = parseFloat(currentOrder.BasePrice);
    let combinationPrice = 0.0;
    let variations = [];
    let combinations = currentOrder.ProductCombinations;
    for (let i = 0; i < combinations.length; i++) {
      combinationPrice += parseFloat(combinations[i].ProductCombinationPrice);
      variations.push(combinations[i].OptionValue);
    }
    let sum = combinationPrice + basePrice;
    let variationString = variations.join(" , ");
    if (status === 0) {
      return sum;
    } else {
      return variationString;
    }
  };

  // var orderTotalAmount = (status) => {
  //   var currentOrder = order.ProductDetail;
  //   var totalAmount = 0;
  //   let totalTax = 0;
  //   let shipping = 0;
  //   for (let i = 0; i < currentOrder.length; i++) {
  //     // totalTax += parseFloat(currentOrder[i].ItemsEstimatedTax);
  //     // shipping += parseFloat(currentOrder[i].ItemsShippingHandling);
  //     totalTax += parseFloat(currentOrder[i].EstimatedTax);
  //     shipping += parseFloat(currentOrder[i].ShippingHandling);

  //     var combinations = currentOrder[i].ProductCombinations;
  //     var combinationPrices = 0;
  //     for (let j = 0; j < combinations.length; j++) {
  //       combinationPrices =
  //         combinationPrices +
  //         parseFloat(combinations[j]["ProductCombinationPrice"]);
  //     }
  //     totalAmount = parseFloat(order.OrderTotal) + combinationPrices;
  //   }

  //   const region = localStorage.getItem('region')

  //   console.log(totalAmount, totalTax, shipping, "shippingshipping");
  //   if (status === 1) {
  //     if (region == 'United States') {
  //       return totalAmount + totalTax + shipping;
  //     }
  //     return totalAmount
  //   } else if (status === 2) {
  //     return totalTax;
  //   } else {
  //     return shipping;
  //   }
  // };

  var orderTotalAmount = (status) => {
    var currentOrder = order.ProductDetail;
    var totalAmount = 0;
    let totalTax = 0;
    let shipping = 0;

    let addTotal = 0;

    for (let i = 0; i < currentOrder.length; i++) {
      console.log(
        currentOrder[i],
        "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
      );

      addTotal += parseFloat(currentOrder[i].ItemsEstimatedTax);
      shipping += parseFloat(currentOrder[i].ItemsShippingHandling);

      let myTotal =
        parseFloat(currentOrder[i].ItemsEstimatedTax) +
        +parseFloat(currentOrder[i].ItemsShippingHandling);
      parseFloat(currentOrder[i].BasePrice);

      var combinations = currentOrder[i].ProductCombinations;
      var combinationPrices = 0;
      for (let j = 0; j < combinations.length; j++) {
        combinationPrices =
          combinationPrices +
          parseFloat(combinations[j]["ProductCombinationPrice"]);
      }
      totalAmount = parseFloat(order.OrderTotal) + combinationPrices;
    }

    if (status === 1) {
      return totalAmount;
    } else if (status === 2) {
      return totalTax;
    } else {
      return shipping;
    }
  };

  let getDateDifference = (orderDate) => {
    var now = moment(new Date());
    var end = moment(orderDate).format("YYYY-MM-DD");
    var duration = moment.duration(now.diff(end));
    var days = duration.asDays();

    return days >= 14 ? false : true;
    // return true;
  };

  const handleDeleteClick = async (order) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to proceed with the deletion?", // Changed text here
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6f9642", // Bootstrap "success" color
      cancelButtonColor: "#d33", // Bootstrap "danger" color
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true); // Show loader

        try {
          const response = await BanglaBazarApi.post(
            `${Endpoint}/api/payment/delete-pending-order`,
            {
              orderNumber: order.OrderNumber,
            }
          );

          setIsLoading(false); // Hide loader

          // Check if the response is successful
          if (response) {
            toast.success("Pending Order Deleted Successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            // Handle error here
            Swal.fire("Error", "Something went wrong!", "error");
          }
        } catch (error) {
          setIsLoading(false); // Hide loader in case of error
          // Handle error here
          Swal.fire("Error", "Network error!", "error");
        }
      }
    });
  };

  return (
    <Card>
      <CardHeader className="bg-white">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Link
              to={`/order-details/${order.OrderNumber}`}
              className="td-none"
            >
              <div style={{ fontSize: "15px" }}>
                <u> Order: {order.OrderNumber}</u>{" "}
              </div>
            </Link>

            <div>
              <span className="text-secondary">
                Placed Date: {moment(order.OrderDate).format("DD-MM-YYYY")}
              </span>
            </div>
          </div>
          <div>
            {order.PaymentStatus.toLowerCase() === "failed" && (
              <Link
                className="td-none text-orange"
                to="#"
                style={{ fontSize: "15px" }}
              >
                Payment Failed
              </Link>
            )}
            {order.PaymentStatus.toLowerCase() === "cancel" && (
              <Link
                className="td-none text-orange"
                to="#"
                style={{ fontSize: "15px" }}
              >
                Payment Cancelled
              </Link>
            )}
            {order.PaymentStatus.toLowerCase() === "paid" && (
              <Link
                className="td-none text-orange"
                to="#"
                style={{ fontSize: "15px" }}
              >
                Paid
              </Link>
            )}
            {order.PaymentStatus.toLowerCase() === "pending" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <Link
                  className="td-none text-orange"
                  to="#"
                  style={{ fontSize: "15px" }}
                >
                  Pending
                </Link>
                <button
                  style={{
                    background: "transparent",
                    border: "none",
                    padding: 0,
                    margin: 0,
                    cursor: "pointer",
                    color: "red",
                    marginLeft: "8px",
                  }}
                  onClick={() => handleDeleteClick(order)}
                >
                  {isLoading ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    <i className="fas fa-trash"></i>
                  )}
                </button>
              </div>
            )}

            {order.PaymentStatus.toLowerCase() === "unpaid" && (
              <Link
                className="td-none text-danger"
                to="#"
                style={{ fontSize: "15px" }}
              >
                Unpaid
              </Link>
            )}
          </div>
          <div>
            <Link
              className="td-none text-dark"
              to="#"
              style={{ fontSize: "15px" }}
              onClick={() => setIsCollpased(!IsCollpase)}
            >
              {IsCollpase ? (
                <span>
                  Hide Details <i className="fas fa-chevron-up"></i>
                </span>
              ) : (
                <span>
                  Show Details <i className="fas fa-chevron-down"></i>
                </span>
              )}
            </Link>
          </div>
        </div>
      </CardHeader>
      <CardBody className={IsCollpase ? "" : "p-0"}>
        <Collapse isOpen={IsCollpase}>
          {console.log(order.DeliveryStatus, " order.DeliveryStatus")}
          {order &&
            (order.DeliveryStatus === "usps_intl" ||
              order.DeliveryStatus === "dhl") && (
              <div>
                {" "}
                <span className="text-orange">
                  Global orders cannot be refunded
                </span>
              </div>
            )}
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <span>
                Transaction ID :{" "}
                <Link
                  to={`/order-details/${order.OrderNumber}`}
                  className="text-orange"
                >
                  {order.TransactionID}
                </Link>
              </span>
            </div>

            {order &&
              order.DeliveryDate &&
              order.DeliveryStatus != "usps_intl" &&
              order.s != "dhl" &&
              getDateDifference(order.DeliveryDate) && (
                <div>
                  {" "}
                  <span>
                    <Link
                      to={`/request-refund/${order.OrderNumber}`}
                      className="text-dark"
                    >
                      Request Refund
                    </Link>
                  </span>
                </div>
              )}
          </div>
          {order.ProductDetail.map((item, index) => (
            <div className="row mt-2" key={index}>
              <div className="col-2 ">
                <a>
                  <img
                    className="w-100"
                    style={{ maxWidth: "fit-content", height: "75px" }}
                    src={`${Endpoint}/${item.Small}`}
                    onClick={() =>
                      history.push(`/product-details/${item.ProductID}`)
                    }
                  />
                </a>
              </div>
              <div className="col-6 m-auto">
                <div>
                  <div
                    style={{
                      cursor: "pointer",
                      ":hover": {
                        color: "blue",
                      },
                    }}
                    onClick={() =>
                      history.push(`/product-details/${item.ProductID}`)
                    }
                  >
                    <h5 className="mb-0">{item.Title}</h5>
                  </div>

                  <div className="pt-1 pb-1">
                    <p className="mb-0" style={{ fontWeight: "600" }}>
                      Variations: {GetPriceOrVariation(item, 1)}
                    </p>
                  </div>
                  <div className="text-orange" style={{ fontSize: "13px" }}>
                    Price: {item.Currency} {GetPriceOrVariation(item, 0)}
                  </div>
                </div>
              </div>
              <div className="col-4 m-auto">
                <span className="dotd-rate">
                  {RatingStars(item.AVG_Rating ? parseInt(item.AVG_Rating) : 0)}{" "}
                  ({item.REVIEW_COUNT})
                </span>
              </div>
            </div>
          ))}
        </Collapse>
      </CardBody>
      <CardFooter>
        <div className="d-flex justify-content-between">
          <div style={{ textAlign: "right" }}>
            Total Tax :{" "}
            <span>
              <b className="text-default">
                {order.ProductDetail[0].Currency}
                {(order && order.EstimatedTax) || "0.0"}
              </b>
            </span>
          </div>
          <div style={{ textAlign: "right" }}>
            Total Shipping :{" "}
            <span>
              <b className="text-default">
                {order.ProductDetail[0].Currency}{" "}
                {order && order.ShippingHandling}
              </b>
            </span>
          </div>
          <div style={{ textAlign: "right" }}>
            Totals Paid :{" "}
            <span>
              <b className="text-default">
                {order.ProductDetail[0].Currency}{" "}
                {/* {order && orderTotalAmount(1).toFixed(2)} */}
                {order && order.OrderTotal}
              </b>
            </span>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}

export default OrderProductOrder;
