import Loading from "./../../../../Utils/Loading";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CurrentUser } from "./../../../../Helpers/Auth";
import firetoast from "./../../../../Helpers/FireToast";
import Endpoint from "./../../../../Utils/Endpoint";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import NoStore from "../../../../assets/images/no-store.svg";
import { RequiredField } from "./../../../../Utils/Required-field";
import CheckEmpty from "./../../../../Utils/CheckEmpty";
import CsvDownload from "react-json-to-csv";
import Icons from "./../../../../Utils/Icons";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import BanglaBazarApi from "../../../Api/BanglaBazarApi";
const xlsx = require("xlsx");

function DepartmentManagement() {
  const history = useHistory();

  const [paginate, setPaginate] = useState({
    offset: 0,
    limit: 10,
  });

  // useEffect(() => {
  //   const handlePopstate = () => {
  //     window.location.href = document.referrer;
  //   };

  //   window.addEventListener('popstate', handlePopstate);

  //   return () => {
  //     window.removeEventListener('popstate', handlePopstate);
  //   };
  // }, []);

  useEffect(() => {
    const handlePopstate = () => {
      // window.location.reload();
      history.push("/panel/departments");
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  // Define state for each search term
  const [departmentSearch, setDepartmentSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [subcategorySearch, setSubcategorySearch] = useState("");

  // Handler for department search
  const handleDepartmentSearch = (e) => {
    setDepartmentSearch(e.target.value);
    setCategorySearch("");
    setSubcategorySearch("");
    // Rest of your search logic
  };

  // Handler for category search
  const handleCategorySearch = (e) => {
    setCategorySearch(e.target.value);
    setDepartmentSearch("");
    setSubcategorySearch("");
    // Rest of your search logic
  };

  // Handler for subcategory search
  const handleSubcategorySearch = (e) => {
    setSubcategorySearch(e.target.value);
    setDepartmentSearch("");
    setCategorySearch("");
    // Rest of your search logic
  };

  const [Name, setName] = useState("");
  const [DepartmentPic, setDepartmentPic] = useState("");
  const [Description, setDescription] = useState("");
  const [modal, setAddModal] = useState(false);
  const [editModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [department, setDepartments] = useState([]);
  const [DigitalProduct, setDigitalProduct] = useState("N");
  const [ShippingGlobal, setShippingGlobal] = useState("N");
  const [Active, setActive] = useState("N");
  const [editID, setEditID] = useState("");
  const [deleteID, setDeleteID] = useState("");
  const [exports, setExport] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);

  const [isLoadingPagin, setIsLoadingPagi] = useState(false);

  var setItemToBeEdited = (item) => {
    console.log(item);
    setName(item.Department);
    setEditID(item.DepartmentID);
    setDescription(item.Description);
    setDepartmentPic(item.DepartmentPic);
    setDigitalProduct(item.DigitalProduct);
    setShippingGlobal(item.ShippingGlobal);
    setActive(item.Active);
    setEditModal(!editModal);
  };

  var setModal = async (val) => {
    if (val) {
      await resetState();
      setAddModal(val);
    } else {
      setAddModal(val);
    }
  };
  var setEditModal = async (val) => {
    if (!val) {
      await resetState();
      setUpdateModal(val);
    } else {
      setUpdateModal(val);
    }
  };
  var deleteDepartment = async () => {
    try {
      var response = await BanglaBazarApi.delete(
        `${Endpoint}/api/department/delete-department/${deleteID}`
      );
      if (response.data.status) {
        firetoast("Department Removed!", "success", 3000, "top-right");
        setDeleteModal(!deleteModal);
        getDepartments();
      } else {
        firetoast(
          response.data.error || response.data.message,
          "error",
          3000,
          "top-right"
        );
        setDeleteID("");
        setDeleteModal(!deleteModal);
      }
    } catch (e) {
      setDeleteID("");
      firetoast(
        "Something went wrong while removing departments",
        "error",
        4000,
        "top-right"
      );
    }
  };

  var paginateData = (goTo) => {
    var numOfPages = Math.ceil(totalRecords / paginate.limit);
    //console.log("called");
    var offset = paginate.offset + 1;
    console.log(offset, numOfPages);
    if (goTo === "next" && paginate.offset < numOfPages - 1) {
      setIsLoading(true);
      if (offset < numOfPages) {
        console.log(offset, numOfPages);
        paginate.offset = paginate.offset + 1;
        // //console.log(paginate)
        setPaginate(paginate);
        getDepartments();
        setIsLoading(false);
      }
    } else if (goTo === "previous" && paginate.offset > 0) {
      //console.log("previous");
      if (paginate.offset > 0) {
        setIsLoading(true);
        paginate.offset = paginate.offset - 1;
        // //console.log(paginate)
        setPaginate(paginate);
        getDepartments();
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  var getDepartmentsBySearch = async (search = "") => {
    // Check if the search term is empty
    if (search.trim() === "") {
      // If search is empty, call getDepartments
      await getDepartments();
      return; // Exit the function to avoid running the search logic
    }

    try {
      var response = await BanglaBazarApi.post(
        `${Endpoint}/api/department/search-by-Department-Category-SubCategory-Name`,
        {
          searchItem: search,
        }
      );

      setDepartments(response.data.SearchResult);
      setTotalRecords(response.data.total_records);
      getDepartmentsByLimit(response.data.total_records);
    } catch (e) {
      firetoast(
        "Something went wrong while getting departments",
        "error",
        4000,
        "top-right"
      );
    }
  };

  // Ensure getDepartments is defined elsewhere in your code

  var getDepartments = async (search = "") => {
    try {
      var form = new URLSearchParams();
      form.append("limit", paginate.limit);
      form.append("offset", paginate.offset);

      if (search) {
        form.append("searchTerm", search); // Append the search term if provided
      }

      var response = await BanglaBazarApi.post(
        `${Endpoint}/api/department/get-department`,
        form
      );
      setInitialLoad(false);
      setDepartments(response.data.Department);
      console.log(response.data.Department);
      setTotalRecords(response.data.total_records);
      getDepartmentsByLimit(response.data.total_records);
    } catch (e) {
      firetoast(
        "Something went wrong while getting departments",
        "error",
        4000,
        "top-right"
      );
    }
  };

  var getDepartmentsByLimit = async (limit) => {
    try {
      var form = new URLSearchParams();
      form.append("limit", limit);
      form.append("offset", 0);
      var response = await BanglaBazarApi.post(
        `${Endpoint}/api/department/get-department`,
        form
      );
      //  setExport(response.data.Department);
    } catch (e) {}
  };

  var excelSheetDepartment = async (limit) => {
    try {
      var form = new URLSearchParams();
      form.append("limit", limit);
      form.append("offset", 0);
      var response = await BanglaBazarApi.get(
        `${Endpoint}/api/department/export-department-csv `
      );
      console.log(response.data.organizedDataArray);
      const flattenedData = extractData(response.data.organizedDataArray);
      createExcelSheet(flattenedData);
    } catch (e) {}
  };

  // const createExcelSheet = (data) => {
  //   const ws = xlsx.utils.json_to_sheet(data);

  //   // Calculate maximum length of each column
  //   const columnWidths = data.reduce((widths, row) => {
  //     Object.keys(row).forEach((key, index) => {
  //       const length = row[key] ? row[key].toString().length : 0;
  //       widths[index] = Math.max(widths[index] || 10, length);
  //     });
  //     return widths;
  //   }, []);

  //   // Set the width for each column in the worksheet
  //   ws["!cols"] = columnWidths.map((w) => ({ wch: w }));

  //   const wb = xlsx.utils.book_new();
  //   xlsx.utils.book_append_sheet(wb, ws, "Departments");
  //   xlsx.writeFile(wb, "Departments.xlsx");
  // };

  const createExcelSheet = (data) => {
    // Convert JSON data to a worksheet
    const ws = xlsx.utils.json_to_sheet(data);

    // Style for the header: green background and white text
    const headerStyle = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "00FF00" }, // Green background
      },
      font: {
        color: { rgb: "FFFFFF" }, // White text
        bold: true,
      },
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
    };

    // Apply the style to the first row (header)
    const range = xlsx.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellRef = xlsx.utils.encode_col(C) + "1"; // Excel is 1-indexed
      if (ws[cellRef]) {
        ws[cellRef].s = headerStyle;
      }
    }

    // Calculate maximum length of the first three columns
    const columnWidths = data.reduce((widths, row) => {
      Object.keys(row).forEach((key, index) => {
        if (index < 3) {
          const length = row[key] ? row[key].toString().length : 0;
          widths[index] = Math.max(widths[index] || 10, length);
        }
      });
      return widths;
    }, []);

    // Set the width for the first three columns and default width for others
    ws["!cols"] = columnWidths.map((width, index) => {
      return { wch: width };
    });

    for (let i = columnWidths.length; i < Object.keys(data[0]).length; i++) {
      ws["!cols"].push({ wch: 10 });
    }

    // Create a new workbook and append the worksheet
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Departments");

    // Write the workbook to a file
    xlsx.writeFile(wb, "Departments.xlsx");
  };

  // Example usage
  // createExcelSheet([{ Name: 'Dept A', Manager: 'John', Location: 'Building 1' }, { Name: 'Dept B', Manager: 'Jane', Location: 'Building 2' }]);

  const extractData = (data) => {
    let extractedData = [];

    data.forEach((department) => {
      if (department.categories && department.categories.length) {
        department.categories.forEach((category) => {
          if (category.subcategories && category.subcategories.length) {
            category.subcategories.forEach((subcategory) => {
              extractedData.push({
                Department: department.Department,
                Category: category.Category,
                SubCategory: subcategory.SubCategory,
                Active: department.Active,
                DepartmentPic: department.DepartmentPic,
                Description: department.Description,
                DigitalProduct: department.DigitalProduct,
                LastUpdate: department.LastUpdate,
                ShippingGlobal: department.ShippingGlobal,
              });
            });
          } else {
            extractedData.push({
              Department: department.Department,
              Category: category.Category,
              SubCategory: "",
              Active: department.Active,
              DepartmentPic: department.DepartmentPic,
              Description: department.Description,
              DigitalProduct: department.DigitalProduct,
              LastUpdate: department.LastUpdate,
              ShippingGlobal: department.ShippingGlobal,
            });
          }
        });
      } else {
        extractedData.push({
          Department: department.Department,
          Category: "",
          SubCategory: "",
          Active: department.Active,
          DepartmentPic: department.DepartmentPic,
          Description: department.Description,
          DigitalProduct: department.DigitalProduct,
          LastUpdate: department.LastUpdate,
          ShippingGlobal: department.ShippingGlobal,
        });
      }
    });
    setExport(extractedData);

    return extractedData;
  };

  const handleDownloadClick = () => {
    excelSheetDepartment(/* your limit parameter here */);
  };

  var EditDepartment = async () => {
    if (CheckEmpty(Name)) {
      return firetoast(
        "Department name can't be empty",
        "error",
        3000,
        "top-right"
      );
    }
    if (CheckEmpty(DepartmentPic)) {
      return firetoast(
        "Department picture is required",
        "error",
        3000,
        "top-right"
      );
    }
    if (CheckEmpty(Description)) {
      return firetoast("Description is empty", "error", 3000, "top-right");
    }
    try {
      var form = new FormData();
      form.append("Department", Name);
      form.append("Description", Description);
      form.append("DepartmentPic", DepartmentPic);
      form.append("DigitalProduct", DigitalProduct);
      form.append("ShippingGlobal", ShippingGlobal);
      form.append("Active", Active);
      var response = await BanglaBazarApi.put(
        `${Endpoint}/api/department/update-department/${editID}`,
        form
      );
      if (response.data.status) {
        firetoast("Department Updated!", "success", 3000, "top-right");
        setTimeout(async () => {
          await resetState();
        }, 1000);
        getDepartments();
      } else {
        firetoast(
          response.data.message || response.data.error,
          "success",
          3000,
          "top-right"
        );
      }
    } catch (e) {
      firetoast(
        "Someting went wrong while updating department",
        "error",
        5000,
        "top-center"
      );
    }
  };

  const clearFilter = () => {
    setDepartmentSearch("");
    setCategorySearch("");
    setSubcategorySearch("");
    getDepartments();
  };

  var toggleDeleteModal = (id) => {
    console.log(id);
    setDeleteID(id);
    setDeleteModal(!deleteModal);
  };

  var submitDepartment = async () => {
    if (CheckEmpty(Name)) {
      return firetoast(
        "Department name can't be empty",
        "error",
        3000,
        "top-right"
      );
    }
    if (CheckEmpty(DepartmentPic)) {
      return firetoast(
        "Department picture is required",
        "error",
        3000,
        "top-right"
      );
    }
    if (CheckEmpty(Description)) {
      return firetoast("Description is empty", "error", 3000, "top-right");
    }
    try {
      var form = new FormData();
      form.append("Department", Name);
      form.append("Description", Description);
      form.append("DepartmentPic", DepartmentPic);
      form.append("DigitalProduct", DigitalProduct);
      form.append("ShippingGlobal", ShippingGlobal);
      form.append("Active", Active);
      var response = await BanglaBazarApi.post(
        `${Endpoint}/api/department/add-department`,
        form
      );
      if (response.data.status) {
        firetoast("Department Created!", "success", 3000, "top-right");
        getDepartments();
        setTimeout(async () => {
          await resetState();
        }, 2000);
      } else {
        firetoast(
          response.data.message || response.data.error,
          "success",
          3000,
          "top-right"
        );
      }
    } catch (e) {
      firetoast(
        "Someting went wrong while creating department",
        "error",
        5000,
        "top-center"
      );
    }
  };

  var resetState = () => {
    setPaginate({
      offset: 0,
      limit: 5,
    });
    setName("");
    setDepartmentPic("");
    setDescription("");
    setAddModal(false);
    setUpdateModal(false);
    setDeleteModal(false);
    setIsLoading(false);
    setTotalRecords(0);
    setDigitalProduct("N");
    setShippingGlobal("N");
    setActive("N");
    setEditID("");
    setDeleteID("");
  };

  var numOfPages = Math.ceil(totalRecords / paginate.limit);

  return (
    <div className="mt-5">
      <div className="d-flex justify-content-between">
        <h3 className="ftw-400">Department Management</h3>
        <button className="btn btn-success" onClick={() => setModal(!modal)}>
          Add Department
        </button>
      </div>
      <div className="card mt-2">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
            <div>
              <h6>
                Total Departments :{" "}
                <span className="text-default">{totalRecords}</span>
              </h6>
            </div>
            {/* INLINE SERACH HERE */}

            <div>
              <div
                className="btn-group btn-left-padding"
                role="group"
                aria-label="Basic example"
              >
                <button
                  className="btn btn-light btn-sm text-default"
                  type="button"
                  onClick={() => paginateData("previous")}
                  disabled={paginate.offset === 0} // Disable if on the first page
                >
                  <i className="fa fa-arrow-left"></i>
                </button>
                <button className="btn btn-light btn-sm" type="button">
                  {paginate.offset + 1}
                </button>

                {/* <button
            className="btn btn-light btn-sm text-default"
            type="button"
            onClick={() => paginateData("next")}
            disabled={(paginate.offset + 1) * paginate.limit >= totalRecords} // Disable if no more items to show
        >
            <i className="fa fa-arrow-right"></i>
        </button> */}

                <button
                  className="btn btn-light btn-sm text-default"
                  type="button"
                  onClick={() => paginateData("next")}
                  disabled={paginate.offset >= numOfPages - 1}
                >
                  <i className="fa fa-arrow-right"></i>
                </button>
              </div>

              <Button
                className="text-secondary"
                style={{ backgroundColor: "white", border: "white" }}
              >
                <i className="fas fa-sort-amount-down-alt text-dark"></i> Filter
              </Button>

              <CsvDownload
                data={exports}
                filename="departments.csv"
                className={`btn btn-default-outline ${
                  department.length === 0 ? "disabled" : ""
                }`}
                onClick={
                  department.length > 0 ? handleDownloadClick : undefined
                }
              >
                Export <i className="fas fa-arrow-alt-to-bottom"></i>
              </CsvDownload>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <h6 className="ftw-400 text-default">Search</h6>
            <button
              onClick={clearFilter}
              className="btn btn-success"
              style={{ marginLeft: "10px" }}
            >
              Clear{" "}
            </button>
          </div>

          <div className="row m-0 mt-3">
            <div className="col-4">
              <div className="form-group row">
                {/* <label for="staticEmail" className="col-sm-4 col-form-label">
                  By Name :
                </label> */}
                <div className="col-sm-12">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="StoreSearch"
                      placeholder="By Department Name"
                      value={departmentSearch}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setDepartmentSearch(e.target.value);
                        setCategorySearch("");
                        setSubcategorySearch("");
                        if (e.target.value === "") {
                          paginate.offset = 0;
                          setPaginate(paginate);
                          getDepartmentsBySearch(e.target.value);
                        }
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          paginate.offset = 0;
                          setPaginate(paginate);
                          getDepartmentsBySearch(searchTerm);
                        }
                      }}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-success"
                        style={{ padding: "9px 12px" }}
                        onClick={() => {
                          paginate.offset = 0;
                          setPaginate(paginate);
                          getDepartmentsBySearch(searchTerm);
                        }}
                      >
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group row">
                {/* <label for="staticEmail" className="col-sm-4 col-form-label">
                  By Phone :
                </label> */}
                <div className="col-sm-12">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="StorePhone"
                      placeholder="By Category Name"
                      value={categorySearch}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setCategorySearch(e.target.value);
                        setDepartmentSearch("");
                        setSubcategorySearch("");
                        if (e.target.value === "") {
                          paginate.offset = 0;
                          setPaginate(paginate);
                          getDepartmentsBySearch(e.target.value);
                        }
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          paginate.offset = 0;
                          setPaginate(paginate);
                          getDepartmentsBySearch(searchTerm);
                        }
                      }}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-success"
                        style={{ padding: "9px 12px" }}
                        onClick={() => {
                          paginate.offset = 0;
                          setPaginate(paginate);
                          getDepartmentsBySearch(searchTerm);
                        }}
                      >
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group row">
                {/* <label for="staticEmail" className="col-sm-4 col-form-label">
                  By Email :
                </label> */}
                <div className="col-sm-12">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="StoreEmail"
                      placeholder="By SubCategory Name"
                      value={subcategorySearch}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setSubcategorySearch(e.target.value);
                        setDepartmentSearch("");
                        setCategorySearch("");
                        if (e.target.value === "") {
                          paginate.offset = 0;
                          setPaginate(paginate);
                          getDepartmentsBySearch(e.target.value);
                        }
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          paginate.offset = 0;
                          setPaginate(paginate);
                          getDepartmentsBySearch(searchTerm);
                        }
                      }}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-success"
                        style={{ padding: "9px 12px" }}
                        onClick={() => {
                          paginate.offset = 0;
                          setPaginate(paginate);
                          getDepartmentsBySearch(searchTerm);
                        }}
                      >
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {department.length > 0 ? (
            <>
              <div className="mt-4 table-responsive">
                {isLoading ? (
                  <div>
                    <Loading />
                  </div>
                ) : (
                  <table className="table table-borderless" id="myTable">
                    <thead>
                      <tr>
                        <th style={{ width: "15%" }}>Name</th>

                        <th>Image</th>
                        <th>Digital Product</th>
                        <th>Shipping Global</th>
                        <th>Active</th>

                        {department.some((item) => item.Category) && (
                          <th>Category</th>
                        )}
                        {department.some((item) => item.SubCategory) && (
                          <th>Sub Category</th>
                        )}

                        <th>Description</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {department.map((item, index) => (
                        <tr key={index}>
                          <td>{item.Department}</td>
                          <td style={{ width: "100px" }}>
                            <img
                              src={`${Endpoint}/${item.DepartmentPic}`}
                              className="img-fluid"
                              alt="dept-img"
                            />
                          </td>
                          <td>
                            {item.DigitalProduct === "Y"
                              ? Icons.GreenTick
                              : Icons.RedCross}
                          </td>
                          <td>
                            {item.ShippingGlobal === "Y"
                              ? Icons.GreenTick
                              : Icons.RedCross}
                          </td>
                          <td>
                            {item.Active === "Y"
                              ? Icons.GreenTick
                              : Icons.RedCross}
                          </td>
                          {item.Category && (
                            <td>
                              {/* category */}
                              <p>{item.Category}</p>
                            </td>
                          )}

                          {item.SubCategory && (
                            <td>
                              {/* Sub Category */}
                              <p>{item.SubCategory}</p>
                            </td>
                          )}

                          <td>{item.Description}</td>
                          <td>
                            {" "}
                            <UncontrolledDropdown>
                              <DropdownToggle color="success">
                                Actions
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() =>
                                    history.push(
                                      `/panel/department-categories/${item.DepartmentID}/${item.Department}`
                                    )
                                  }
                                >
                                  View categories
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setItemToBeEdited(item);
                                  }}
                                >
                                  Edit
                                </DropdownItem>

                                <DropdownItem
                                  onClick={() =>
                                    toggleDeleteModal(item.DepartmentID)
                                  }
                                >
                                  Remove
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </>
          ) : (
            <div className="text-center no-store-container ">
              <div className="mt-3">
                <img src={NoStore} className="img-fluid no-store-img " />
                <h2 className="ftw-400 mt-3">No Department Data Found </h2>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={() => setModal(!modal)}>
          Department Details
        </ModalHeader>
        <ModalBody>
          <div className="row mt-3">
            <div className="col-md-6 col-sm-12">
              <label>
                Department Name <RequiredField />
              </label>
              <input
                className="form-control"
                type="text"
                defaultValue={Name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <label>
                Department Image <RequiredField />
              </label>
              <input
                className="form-control"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => setDepartmentPic(e.target.files[0])}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-12 col-sm-12">
              <label>
                Description <RequiredField />
              </label>
              <textarea
                className="form-control"
                rows={5}
                defaultValue={Description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <hr />
          <div className="mt-3 d-flex justify-content-between">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input default-check-color"
                type="checkbox"
                id="inlineCheckbox1 "
                value={DigitalProduct}
                onChange={(e) => {
                  if (e.target.checked) {
                    setDigitalProduct("Y");
                  } else {
                    setDigitalProduct("N");
                  }
                }}
              />
              <label className="form-check-label" for="inlineCheckbox1">
                Is Digital Product
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input default-check-color"
                type="checkbox"
                id="inlineCheckbox2"
                value={ShippingGlobal}
                onChange={(e) => {
                  if (e.target.checked) {
                    setShippingGlobal("Y");
                  } else {
                    setShippingGlobal("N");
                  }
                }}
              />
              <label className="form-check-label" for="inlineCheckbox2">
                Shipping Global
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input default-check-color"
                type="checkbox"
                id="inlineCheckbox3"
                value={Active}
                onChange={(e) => {
                  if (e.target.checked) {
                    setActive("Y");
                  } else {
                    setActive("N");
                  }
                }}
              />
              <label className="form-check-label" for="inlineCheckbox3">
                Active
              </label>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => submitDepartment()}>
            Create
          </Button>{" "}
        </ModalFooter>
      </Modal>
      {/*Edit Modal*/}
      <Modal
        isOpen={editModal}
        toggle={() => setEditModal(!editModal)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={() => setEditModal(!editModal)}>
          Edit Department Details
        </ModalHeader>
        <ModalBody>
          <div className="row mt-3">
            <div className="col-md-6 col-sm-12">
              <label>
                Department Name <RequiredField />
              </label>
              <input
                className="form-control"
                type="text"
                defaultValue={Name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <label>
                Department Image <RequiredField />
              </label>
              <input
                className="form-control"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => setDepartmentPic(e.target.files[0])}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-12 col-sm-12">
              <label>
                Description <RequiredField />
              </label>
              <textarea
                className="form-control"
                rows={5}
                defaultValue={Description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <hr />
          <div className="mt-3 d-flex justify-content-between">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input default-check-color"
                type="checkbox"
                id="inlineCheckbox1 "
                defaultChecked={DigitalProduct === "Y"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setDigitalProduct("Y");
                  } else {
                    setDigitalProduct("N");
                  }
                }}
              />
              <label className="form-check-label" for="inlineCheckbox1">
                Is Digital Product
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input default-check-color"
                type="checkbox"
                id="inlineCheckbox2"
                defaultChecked={ShippingGlobal === "Y"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setShippingGlobal("Y");
                  } else {
                    setShippingGlobal("N");
                  }
                }}
              />
              <label className="form-check-label" for="inlineCheckbox2">
                Shipping Global
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input default-check-color"
                type="checkbox"
                id="inlineCheckbox3"
                defaultChecked={Active === "Y"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setActive("Y");
                  } else {
                    setActive("N");
                  }
                }}
              />
              <label className="form-check-label" for="inlineCheckbox3">
                Active
              </label>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => EditDepartment()}>
            Update
          </Button>{" "}
        </ModalFooter>
      </Modal>
      {/*Delete Modal*/}
      <Modal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        backdrop="static"
        size="md"
      >
        <ModalHeader toggle={() => setDeleteModal(!deleteModal)}>
          Department Details
        </ModalHeader>
        <ModalBody>
          <div>Are you sure you want to remove this department ?</div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => deleteDepartment()}>
            Remove
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default DepartmentManagement;
