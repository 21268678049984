import React from "react";
import { useContext, useState, useEffect } from "react";
import { PRODUCT_FORM_CONTEXT } from "../../../../Contexts/ProductFormContext";
import { useParams, useLocation } from "react-router-dom";
import firetoast from "../../../../../Helpers/FireToast";
import Resizer from "react-image-file-resizer";
import BanglaBazarApi from "../../../../Api/BanglaBazarApi";
import Endpoint from "../../../../../Utils/Endpoint";
import CheckEmpty from "../../../../../Utils/CheckEmpty";
import { CurrentUser } from "../../../../../Helpers/Auth";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Alert,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { RequiredField } from "../../../../../Utils/Required-field";
import OptionValueField from "../Edit Product/OptionValueField";

const ProductVariants = ({ Active, ReviewedByAdmin }) => {
  const history = useHistory();
  const [selectedOptions, setSelectedOptions] = useState({});

  var [VariationValues, setVariationValues] = useState([]);
  var [vaiantValues, setVariantValues] = useState([]);
  console.log(vaiantValues, "vaiantValues");
  var [optionid, setOptionid] = useState(null);
  var [showModal, setshowModal] = useState(false);
  var [AvailabaleValues, setAvailabaleValues] = useState([]);
  var [isAddCustom, setIsAddCustom] = useState(false);
  var [ModalSmall, setModalSmall] = useState({});
  var [ModalMedium, setModalMedium] = useState({});
  var [ModalLarge, setModalLarge] = useState({});
  var [ModalButton, setModalButton] = useState(false);
  var [ModalPrice, setModalPrice] = useState("0");
  var [disableBtn, setDisableBtn] = useState(false);
  const [editeAble, setEditeABle] = useState(false);
  const [optionWithValues, setOptionWithValues] = useState([]);

  var [smallImage, setSmallImage] = useState(null);
  var [meduimImage, setMeduimImage] = useState(null);
  var [LargeImage, setLargeImage] = useState(null);
  const [updatedValues, setUpdatedValues] = useState({});

  // Step 2: Event Handler

  const handleInputChange = (event, optionValueID) => {
    const { value } = event.target;
    console.log("optionValueID", value);

    // Create a new object with the updated value
    const updated = { ...updatedValues, [optionValueID]: value };

    console.log("updated", updated);
    // Set the updated values to state
    setUpdatedValues(updated);
  };

  // const handleDropdownChange = (optionId, e) => {
  //   setSelectedOptions(prevState => ({
  //     ...prevState,
  //     [optionId]: e.target.value
  //   }));
  // };
  const handleDropdownChange = (optionId, e) => {
    const selectedText = e.target.options[e.target.selectedIndex].text;
    setSelectedOptions((prevState) => ({
      ...prevState,
      [optionId]: { id: e.target.value, text: selectedText },
    }));
  };

  const handleImageDelete = async (id) => {
    try {
      var response = await BanglaBazarApi.delete(
        `${Endpoint}/api/product/image/${id}`
      );
      console.log("RESPONSE DATA", response.data);
    } catch (err) {
      console.log(err);
    }
  };
  // var { productID } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productID = searchParams.get("productID");
  console.log(productID, "productIDproductIDproductIDproductID");
  const {
    Options,
    setOptions,
    SubCategoryVariants,
    SKU,
    setSKU,
    Price,
    Status,
    ProductID,
    StoreName,
    setPrice,
    AvailableInventory,
    OptionId,
    setOptionId,
    setAvailableInventory,
    setSmall,
    setMedium,
    setLarge,
    OptionValue,
    setOptionValue,
    MainImage,
    Inventory,
    setInventory,
    UnitPrice,
    setUnitPrice,
    TotalPrice,
    setTotalPrice,
    Small,
    Medium,
    Large,
    Variations,
    getOptions,
    Currency,
    AvailableVariations,
  } = useContext(PRODUCT_FORM_CONTEXT);

  useEffect(() => {
    if (productID) {
      getOptions(productID)
    }
  }, [productID])
  console.log("Options", Options)

  const [optionData, setOptionData] = useState({});
  const [varOptions, setVarOptions] = useState({});
  console.log(varOptions, "varOptions");
  console.log(optionData, "optionData");
  useEffect(async () => {
    await getAllVariantAndValues();
  }, []);

  useEffect(() => {
    console.log("selectedOptions", selectedOptions);
  }, [selectedOptions]);

  var getAllVariantAndValues = async () => {
    try {
      var response = await BanglaBazarApi.get(
        `${Endpoint}/api/product/get-productAllDetails/${productID}/${CurrentUser.UserID}`
      );

      const res = response.data.Product;
      const result = response.data.VariantDetails;
      setVariationValues(res);
      setVariantValues(result);
      console.log(
        "response.data.result----------------------------------------------------------->",
        result
      );
    } catch (e) {
      console.log(e);
      firetoast(
        "Something went wrong while getting varaint and values",
        "default-error"
      );
    }
  };
  var UpdateVarantValueImage = async (file) => {
    if (file) {
      // console.log(await VariantImageResizer(file,1,1))

      await Resizer.imageFileResizer(
        file,
        50,
        50,
        "PNG",
        100,
        0,
        (uri) => {
          setSmall(uri);
        },
        "file"
      );
      await Resizer.imageFileResizer(
        file,
        200,
        200,
        "PNG",
        100,
        0,
        (uri) => {
          setMedium(uri);
        },
        "file"
      );
      await Resizer.imageFileResizer(
        file,
        400,
        550,
        "PNG",
        100,
        0,
        (uri) => {
          setLarge(uri);
        },
        "file"
      );
    }
  };

  useEffect(() => {
    if (Active === "N" && ReviewedByAdmin === "N") {
      setEditeABle(true);
    }
  }, [Active, ReviewedByAdmin]);

  //! there is the value

  var submitOptionValueDetail = async (id, item) => {
    var {
      VariantValue,
      SKU,
      VariantPrice,
      AvailableInventory,
      Inventory,
      UnitPrice,
      TotalPrice,
      Small,
      Medium,
      Large,
      MainImage,
      variantionValues,
      images,
      video,
    } = item;

    console.log("item----------------------->", item);

    item.variantionValues.map((item, index) => {
      if (item.VariantValue == "" || item.VariantValue == null) {
        return firetoast(
          `Option Value Name for ${item.VariantName} is required`,
          "default-error"
        );
      }
    });

    // Price = item.variantionValues[0].VariantPrice

    // return
    if (CheckEmpty(VariantValue)) {
      return firetoast("Option Value Name is required", "default-error");
    }
    if (CheckEmpty(SKU)) {
      return firetoast("SKU is required", "default-error");
    }

    if (CheckEmpty(AvailableInventory)) {
      return firetoast("Available Inventory is required", "default-error");
    }
    if (CheckEmpty(Inventory)) {
      return firetoast("Inventory is required", "default-error");
    }
    if (parseInt(AvailableInventory) > parseInt(Inventory)) {
      return firetoast(
        "Available Inventory should be less than Inventory",
        "default-error"
      );
    }
    if (CheckEmpty(UnitPrice)) {
      return firetoast("Unit Price is required", "default-error");
    }
    if (CheckEmpty(TotalPrice)) {
      return firetoast("Total Price is required", "default-error");
    }
    if (MainImage === "Y") {
      var found = false;

      for (var i = 0; i < VariationValues?.length; i++) {
        if (
          VariationValues[i].VariantValue !== VariantValue &&
          VariationValues[i].MainImage === "Y"
        ) {
          found = true;
        }
      }
      if (found) {
        return firetoast(
          "Only one Main Image can be set from the available variant values",
          "error",
          3000,
          "top-right"
        );
      }
    }
    try {
      console.log(video, images, "0000000000000000000000000000");

      var form = new FormData();
      if (images && images.length > 0) {
        for (const file of images) {
          form.append("largeImage", file.large);
          form.append("smalImage", file.small);
          form.append("meduimImage", file.medium);
        }
      }
      form.append("ProductID", productID);
      form.append("variantionValues", JSON.stringify(variantionValues));
      form.append("video", video);
      form.append("StoreName", StoreName);
      form.append("OptionID", optionid);
      form.append("OptionValue", VariantValue);
      form.append("MainImage", MainImage);
      form.append("SKU", SKU);
      form.append("Price", VariantPrice);
      form.append("AvailableInventory", AvailableInventory);
      form.append("Inventory", Inventory);
      form.append("UnitPrice", UnitPrice);
      form.append("TotalPrice", TotalPrice);
      form.append("Status", Status);
      form.append("Small", Small);
      form.append("Medium", Medium);
      form.append("Large", Large);

      var response = await BanglaBazarApi.put(
        `${Endpoint}/api/product/updateProduct-Form2/${id}`,
        form
      );
      getAllVariantAndValues();

      console.log(response);
      if (response.data.status) {
        firetoast(
          "Option Value updated Added Successfully",
          "success",
          3000,
          "top-right"
        );
        // getAllVariantAndValues();
      } else {
        var { message, error } = response.data;
        return firetoast(message || error, "default-error");
      }
    } catch (e) {
      console.log(e);
      return firetoast(
        "Something went wrong while updating option value deatils",
        "default-error"
      );
    }
  };
  var AddNewOptionValue = async (item) => {
    var {
      VariantValue,
      SKU,
      Price,
      AvailableInventory,
      Inventory,
      UnitPrice,
      TotalPrice,
      Small,
      Medium,
      Large,
    } = item;

    console.log("------------------------------------", item);

    if (CheckEmpty(VariantValue)) {
      return firetoast("Option Value Name is required", "default-error");
    }
    if (CheckEmpty(SKU)) {
      return firetoast("SKU is required", "default-error");
    }
    if (CheckEmpty(Price)) {
      return firetoast("Price is required", "default-error");
    }
    if (CheckEmpty(AvailableInventory)) {
      return firetoast("Available Inventory is required", "default-error");
    }

    if (CheckEmpty(Inventory)) {
      return firetoast("Inventory is required", "default-error");
    }
    if (parseInt(AvailableInventory) > parseInt(Inventory)) {
      return firetoast(
        "Available Inventory should be less than Inventory",
        "default-error"
      );
    }
    if (CheckEmpty(UnitPrice)) {
      return firetoast("Unit Price is required", "default-error");
    }
    if (CheckEmpty(TotalPrice)) {
      return firetoast("Total Price is required", "default-error");
    }
    if (MainImage === "Y") {
      var found = 0;

      for (var i = 0; i < VariationValues.length; i++) {
        if (
          VariationValues[i].VariantValue !== VariantValue &&
          VariationValues[i].MainImage === "Y"
        ) {
          found += 1;
        }
      }
      if (VariationValues[0].MainImage === "Y") {
        return firetoast(
          "Only one Main Image can be set from the available variant values",
          "error",
          3000,
          "top-right"
        );
      }
    }
    try {
      var form = new FormData();
      form.append("ProductID", productID);
      form.append("StoreName", StoreName);
      form.append("OptionID", optionid);
      form.append("OptionValue", VariantValue);
      form.append("MainImage", MainImage);
      form.append("SKU", SKU);
      form.append("Price", Price);
      form.append("AvailableInventory", AvailableInventory);
      form.append("Inventory", Inventory);
      form.append("UnitPrice", UnitPrice);
      form.append("TotalPrice", TotalPrice);
      form.append("Status", Status);
      form.append("Small", Small);
      form.append("Medium", Medium);
      form.append("Large", Large);

      var response = await BanglaBazarApi.post(
        `${Endpoint}/api/product/addProduct-Form2`,
        form
      );
      if (response.data.status) {
        firetoast(
          "Option Value Details Added Successfully",
          "success",
          3000,
          "top-right"
        );
        getAllVariantAndValues();
        clearDetails();
      } else {
        var { message, error } = response.data;
        return firetoast(message || error, "default-error");
      }
    } catch (e) {
      console.log(e);
      return firetoast(
        "Something went wrong while adding option value deatils",
        "default-error"
      );
    }
  };
  var clearDetails = () => {
    setOptionId("null");
    setOptionValue("");
    setSKU("");
    setPrice("");
    setInventory("");
    setTotalPrice("");
    setAvailableInventory("");
    setSmall("");
    setMedium("");
    setLarge("");
  };
  let getOptionValues = async (id) => {
    try {
      const response = await BanglaBazarApi.get(
        `${Endpoint}/api/product/get-productVariantDetail/${id}`
      );
      var data = response.data.ProductVariants;
      for (let i = 0; i < data.length; i++) {
        data[i].isNew = false;
        data[i].UnitPrice = 0;
        data[i].TotalPrice = 0;
      }
      setVariationValues(data);
    } catch (e) {
      firetoast(
        "Something went wrong while fething option values",
        "default-error"
      );
    }
  };
  let getOPtionsWithValue = async (id) => {
    try {
      const response = await BanglaBazarApi.get(
        `${Endpoint}/api/product/get-productVariantOptionWithValues/${id}`
      );
      var data = response.data.ProductVariantOptions;
      console.log("data---------------------------------------------", data);
      setOptionWithValues(data);
    } catch (e) {
      firetoast(
        "Something went wrong while fething option values",
        "default-error"
      );
    }
  };

  useEffect(() => {
    getOPtionsWithValue(productID);
  }, [productID]);

  let handleVariationValueChange = (e, index, i) => {
    // console.log(e.target.value, '-----------------------------');
    var array = [...VariationValues];

    array[index][e.target.name] = e.target.value;
    if (e.target.name === "VariantPrice") {
      array[index]["Price"] = e.target.value;
    }
    setVariationValues(array);

    let variant_array = [...vaiantValues];
    // variant_array[index][e.target.name] = e.target.value;

    console.log({ id: e.target.id, i, index });

    if (i !== undefined) {
      variant_array[index].variantionValues[i][e.target.name] = e.target.value;
    } else {
      variant_array[index][e.target.name] = e.target.value;
      if (e.target.name === "VariantPrice") {
        array[index]["Price"] = e.target.value;
      }
    }

    console.log(variant_array, "-------------------");
    setVariantValues(variant_array);
  };

  let handleVariationValueMainImageChange = (e, index) => {
    var array = [...VariationValues];
    array[index].MainImage = e;
    setVariationValues(array);
  };
  let setVariationList = async () => {
    let foundVariationLabel = "";
    for (let i = 0; i < Variations.length; i++) {
      if (parseInt(Variations[i].value) === parseInt(optionid)) {
        console.log("inside");
        foundVariationLabel = Variations[i].label;
      }
    }
    let foundVariationId = "";
    for (let i = 0; i < AvailableVariations.length; i++) {
      if (AvailableVariations[i].label === foundVariationLabel) {
        foundVariationId = AvailableVariations[i].id;
      }
    }
    console.log(foundVariationLabel, "foundVariationLabel");
    console.log(AvailableVariations, "AvailableVariations");
    console.log(optionid, "optionid");
    console.log(foundVariationId, "foundVariationId");
  };

  const handleVideoUpload = async (file, index) => {
    let array = [...vaiantValues];

    const selectedFile = file;
    if (selectedFile) {
      const validVideoTypes = ["video/mp4", "video/mpeg", "video/quicktime"]; // Add more MIME types if needed

      if (validVideoTypes.includes(selectedFile.type)) {
        // It's a valid video file
        array[index]["video"] = file;
        console.log(`Selected file is a video: ${selectedFile.name}`);
      } else {
        // Not a video file
        console.log("Selected file is not a valid video file.");
        return firetoast("Selected File is not a video type.", "default-error");
      }
    }

    console.log("array=================>", array);
  };

  var handleUpdateVarantValueImage = async (files, index) => {
    let array = [...vaiantValues];

    console.log("------------------------------------>", files, index);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // console.log("-------------------------->", file);

      if (file) {
        let imageDetails = { small: {}, medium: {}, large: {} };

        await Resizer.imageFileResizer(
          file,
          300,
          300,
          "JPEG",
          100,
          0,
          (uri) => {
            imageDetails.small = uri; // replace 'details' with the actual data if needed
          },
          "file",
          1,
          1
        );

        await Resizer.imageFileResizer(
          file,
          300,
          300,
          "JPEG",
          100,
          0,
          (uri) => {
            imageDetails.medium = uri; // replace 'details' with the actual data if needed
          },
          "file",
          10,
          10
        );

        await Resizer.imageFileResizer(
          file,
          300,
          300,
          "JPEG",
          100,
          0,
          (uri) => {
            imageDetails.large = uri; // replace 'details' with the actual data if needed
          },
          "file",
          100,
          100
        );

        if (!array[index]["images"]) {
          array[index]["images"] = [];
        }
        array[index]["images"].push(imageDetails);
      }
    }

    console.log("array=================>", array);
    return;
  };
  var AddNewOption = async () => {
    var array = [...VariationValues];
    if (optionid != null) {
      array.push({
        ProductID: "",
        StoreName: "",
        OptionID: "",
        OptionValue: "",
        MainImage: "N",
        SKU: "",
        Price: "",
        AvailableInventory: "",
        Inventory: "",
        UnitPrice: "",
        TotalPrice: "",
        Status: "",
        Small: "",
        Medium: "",
        Large: "",
        isNew: true,
      });
      setVariationValues(array);
    }
  };
  var removeVariantValue = async (variant) => {
    try {
      console.log("--------------------->", variant);
      const response = await BanglaBazarApi.post(
        `${Endpoint}/api/product/delete-productVariantValue`,
        { variant }
      );

      if (response.data.status) {
        firetoast(
          "Variant Value removed successfully",
          "success",
          3000,
          "top-right"
        );
        getOptionValues(optionid);
      }
    } catch (e) {
      firetoast(
        "Something went wrong while removing variant value",
        "default-error"
      );
    }
  };

  var CreateNewValue = async () => {

    let file = document.getElementById("variantImage").files[0];
    let optionIds = [];
    const keys = Object.keys(selectedOptions);
    // const values = Object.values(selectedOptions);

    for (let key of keys) {
      console.log(key + ": " + selectedOptions[key]["id"]);
      optionIds.push(selectedOptions[key]["id"]);
    }

    console.log(
      "selectedOption-------------------s",
      optionIds,
      selectedOptions
    );

    let SKU = document.getElementById("variantSKU").value;
    let Price =
      document.getElementById("variantPrice").value || selectedOptions["Price"];
    let Inventory = document.getElementById("variantInventory").value;
    let AvailableInventory = document.getElementById(
      "variantAvailableInventory"
    ).value;
    let TotalPrice = document.getElementById("variantTotalPrice").value;
    let UnitPrice = document.getElementById("variantUnitPrice").value;

    // console.log("=================>", VariantValue);

    // if (keys.length !== optionWithValues.length) {
    //   setDisableBtn(false);
    //   return firetoast(
    //     "Please all select the required combinations",
    //     "default-error"
    //   );
    // }

    if (CheckEmpty(SKU)) {
      setDisableBtn(false);
      return firetoast("SKU is required", "default-error");
    }
    if (CheckEmpty(Price)) {
      setDisableBtn(false);
      return firetoast("Price is required", "default-error");
    }
    if (CheckEmpty(AvailableInventory)) {
      setDisableBtn(false);
      return firetoast("Available Inventory is required", "default-error");
    }

    if (CheckEmpty(Inventory)) {
      setDisableBtn(false);
      return firetoast("Inventory is required", "default-error");
    }
    if (parseInt(Inventory) < parseInt(AvailableInventory)) {
      setDisableBtn(false);
      return firetoast(
        "Available Inventory should be less than Inventory",
        "default-error"
      );
    }
    if (CheckEmpty(UnitPrice)) {
      setDisableBtn(false);
      return firetoast("Unit Price is required", "default-error");
    }
    if (CheckEmpty(TotalPrice)) {
      setDisableBtn(false);
      return firetoast("Total Price is required", "default-error");
    }
    if (!file) {
      setDisableBtn(false);
      return firetoast("Image is required", "defaulr-error");
    }

    if (file) {
      try {
        const resizePromises = [];

        resizePromises.push(
          new Promise((resolve) => {
            Resizer.imageFileResizer(
              file,
              300,
              300,
              "JPEG",
              100,
              0,
              (uri) => {
                console.log(uri);
                setModalSmall(uri);
                resolve(uri);
              },
              "file",
              1,
              1
            );
          })
        );

        resizePromises.push(
          new Promise((resolve) => {
            Resizer.imageFileResizer(
              file,
              300,
              300,
              "JPEG",
              100,
              0,
              (uri) => {
                console.log(uri);
                setModalMedium(uri);
                resolve(uri);
              },
              "file",
              10,
              10
            );
          })
        );

        resizePromises.push(
          new Promise((resolve) => {
            Resizer.imageFileResizer(
              file,
              300,
              300,
              "JPEG",
              100,
              0,
              (uri) => {
                setModalLarge(uri);
                console.log(uri);
                resolve(uri);
              },
              "file",
              100,
              100
            );
          })
        );

        const [smallUri, mediumUri, largeUri] = await Promise.all(
          resizePromises
        );

        setSmallImage(smallUri);
        setMedium(mediumUri);
        setLargeImage(largeUri);

        try {
          var form = new FormData();
          console.log(
            "selectedOptions['Price'];----------------------->",
            selectedOptions["Price"]
          );
          form.append("optionIds", optionIds);
          form.append("optionValues", JSON.stringify(varOptions));
          form.append("ProductID", productID);
          form.append("StoreName", StoreName);
          form.append("OptionID", optionid);
          form.append("OptionValue", "");
          form.append("MainImage", "N");
          form.append("SKU", SKU);
          form.append("Price", Price);
          form.append("AvailableInventory", AvailableInventory);
          form.append("Inventory", Inventory);
          form.append("UnitPrice", UnitPrice);
          form.append("TotalPrice", TotalPrice);
          form.append("Status", "Y");

          form.append("Small", smallUri);
          form.append("Medium", mediumUri);
          form.append("Large", largeUri);

          var response = await BanglaBazarApi.post(
            `${Endpoint}/api/product/addProduct-Form2`,
            form
          );
          console.log(response);

          if (response.data.status) {
            firetoast(
              "Option Value Details Added Successfully",
              "success",
              3000,
              "top-right"
            );

            getAllVariantAndValues();
            setDisableBtn(false);
            setshowModal(false);
            clearDetails();

            history.push(`/panel/vendor/edit-product/${ProductID}/${CurrentUser.UserID}`);

          // history.push(`/panel/vendor/edit-product/${ProductID}`);

            // /panel/vendor/edit-product/147/193
          } else {
            var { message, error } = response.data;
            return firetoast(message || error, "default-error");
          }
        } catch (e) {
          setDisableBtn(false);
          console.log("----------------------", e.response.data.message);
          if (e.response?.data?.message) {
            return firetoast(e.response.data.message, "default-error");
          }
          return firetoast(
            "Something went wrong while adding option value details",
            "default-error"
          );
        }
      } catch (error) {
        setDisableBtn(false);
        console.log(error);
        return firetoast(
          "Something went wrong while resizing the image",
          "default-error"
        );
      }
    }
  };

  useEffect(() => {
    console.log("SubCategoryVariantsSubCategoryVariants", SubCategoryVariants);
  }, [SubCategoryVariants]);
  useEffect(() => {
    // Define a function to fetch data for a specific OptionID
    const fetchOptionData = async (optionId) => {
      try {
        const response = await BanglaBazarApi.get(
          `${Endpoint}/api/category/get-subcategoryvariantvalue/${optionId}`
        );
        return response.data.SubCategoryVariantValue;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    // Map the options and make Axios API calls
    const fetchOptionDataForOptions = async () => {
      const optionDataPromises = SubCategoryVariants.map((option) => {
        return fetchOptionData(option.OptionID);
      });

      // Wait for all API calls to complete
      Promise.all(optionDataPromises).then((results) => {
        const optionDataObject = {};

        // Map the results to the option names
        SubCategoryVariants.forEach((option, index) => {
          optionDataObject[option.OptionName] = results[index];
        });

        // Set the optionData state with the results
        setOptionData(optionDataObject);
      });
    };

    // Call the function to fetch option data
    fetchOptionDataForOptions();
  }, [SubCategoryVariants]);
  const handleOptionChange = (optionName, selectedValue) => {
    console.log(optionName, "optionName")
    const selectedOption = Options.filter(
      (item) => item.OptionName == optionName
    );
    console.log(Options[0].OptionName == optionName)
    console.log(selectedOption, "selectedOption")

    const id = selectedOption[0].OptionID
    setVarOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [id]: { text: selectedValue },
    }));
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <div style={{ float: "left" }}>
            <button
              // disabled={editeAble}
              className="btn btn-success"
              // onClick={() => AddNewOption()}
              onClick={() => {
                setshowModal(!showModal);
                setVariationList();
              }}
            >
              Add New Variant Value
            </button>
          </div>
        </div>
      </div>
      {vaiantValues &&
        vaiantValues.map((item, index) => {
          return (
            <div
              className="row p-3 m-3"
              style={{ border: "1px solid rgba(128, 128, 128, 0.58) " }}
              key={index}
            >
              <div className="d-flex justify-content-between align-items-center">
                {item.variantionValues.map((variation, i) => {
                  return (
                    <>
                      <div style={{ display: "inline" }}>
                        <h5 className="ftw-400 text-default">
                          {variation.VariantName} - {variation.VariantValue}
                        </h5>
                      </div>
                    </>
                  );
                })}
                {!item.isNew && VariationValues.length > 1 && (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => removeVariantValue(item.variantionValues)}
                  >
                    <i className="fas fa-times text-danger"></i>
                  </div>
                )}
              </div>

              {item.variantionValues.map((variation, i) => {
                return (
                  <div className="col-6">
                    <label>
                      {variation.VariantName} <RequiredField />
                    </label>
                    <input
                      // disabled={editeAble}
                      className="form-control"
                      type="text"
                      name={"VariantValue"}
                      onChange={(e) => {
                        handleVariationValueChange(e, index, i);
                      }}
                      value={variation.VariantValue}
                    />
                  </div>
                );
              })}
              <div className="col-6">
                <label>
                  SKUS <RequiredField />
                </label>
                <input
                  // disabled={editeAble}
                  className="form-control"
                  type="text"
                  value={item.SKU}
                  name="SKU"
                  onChange={(e) =>
                    handleVariationValueChange(e, index, undefined)
                  }
                />
              </div>
              <div className="col-6">
                <label>
                  Price <RequiredField />
                </label>
                <input
                  className="form-control"
                  type="number"
                  // disabled={editeAble}
                  value={item.VariantPrice}
                  onChange={(e) =>
                    handleVariationValueChange(e, index, undefined)
                  }
                  name="VariantPrice"
                />

                <Alert color="success" className="p-1 m-1">
                  Total Price ={" "}
                  {`${Currency}  ${parseFloat(Price ? Price : 0) +
                    parseFloat(item.VariantPrice ? item.VariantPrice : 0)
                    } ,this amount is sum of base price of this product and current variation value price`}{" "}
                </Alert>
              </div>
              <div className="col-6">
                <label>
                  Inventory <RequiredField />
                </label>
                <input
                  className="form-control"
                  type="number"
                  // disabled={editeAble}
                  value={item.Inventory}
                  onChange={(e) =>
                    handleVariationValueChange(e, index, undefined)
                  }
                  name="Inventory"
                />
              </div>
              <div className="col-6">
                <label>
                  Available Inventory <RequiredField />
                </label>
                <input
                  className="form-control"
                  type="number"
                  // disabled={editeAble}
                  onChange={(e) =>
                    handleVariationValueChange(e, index, undefined)
                  }
                  name="AvailableInventory"
                  value={item.AvailableInventory}
                />
              </div>
              <div className="col-6">
                <label>
                  Total Price <RequiredField />
                </label>
                <input
                  className="form-control"
                  type="number"
                  // disabled={editeAble}
                  // value="0"
                  onChange={(e) =>
                    handleVariationValueChange(e, index, undefined)
                  }
                  name="TotalPrice"
                  value={item.TotalPrice}
                />
              </div>
              <div className="col-6">
                <label>
                  Unit Price <RequiredField />
                </label>
                <input
                  // disabled={editeAble}
                  className="form-control"
                  type="number"
                  onChange={(e) =>
                    handleVariationValueChange(e, index, undefined)
                  }
                  name="UnitPrice"
                  value={item.UnitPrice}
                />
              </div>

              <div className="col-6">
                <div className="row ">
                  <div className="col-6 ">
                    <label>
                      Upload Images <RequiredField />
                    </label>
                    <input
                      // disabled={editeAble}
                      className="form-control"
                      type="file"
                      multiple
                      onChange={(e) =>
                        handleUpdateVarantValueImage(e.target.files, index)
                      }
                    />

                    <label>Upload Video </label>
                    <input
                      // disabled={editeAble}
                      className="form-control"
                      type="file"
                      onChange={(e) =>
                        handleVideoUpload(e.target.files[0], index)
                      }
                    />

                    <div className="col-6">
                      {!item.isNew && (
                        <>
                          <div className="form-check form-check-inline">
                            <input
                              // disabled={editeAble}
                              className="form-check-input default-check-color"
                              type="checkbox"
                              id="inlineCheckbox3"
                              defaultChecked={item.MainImage === "Y"}
                              onChange={() =>
                                handleVariationValueMainImageChange(
                                  item.MainImage === "Y" ? "N" : "Y",
                                  index
                                )
                              }
                            />
                            <label
                              className="form-check-label"
                              for="inlineCheckbox3"
                            >
                              Main Image
                            </label>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "320px",
                      height: "240px",
                    }}
                  >
                    {item.video && (
                      <video width="320" height="240" controls>
                        <source
                          src={`${Endpoint}/${item.video}`}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <span
                      style={{
                        position: "absolute",
                        top: "10px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => { }}
                    >
                      ✖
                    </span>
                  </div>
                </div>
              </div>
              <div className="row w-100">
                <div className="col-3 mt-3 ">
                  {item.Medium && (
                    <img
                      // disabled={editeAble}
                      src={`${Endpoint}/${item.Medium}`}
                      className="img-fluid h-100 w-100"
                    />
                  )}
                </div>
                {item.Images?.map((image, i) => (
                  <div className="col-3 mt-3">
                    <div
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        // disabled={editeAble}
                        src={`${Endpoint}/${image.Medium}`}
                        style={{
                          display: "block",
                          maxWidth: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                      <span
                        style={{
                          position: "absolute",
                          top: "3px",
                          right: "10px",
                          cursor: "pointer",
                          backgroundColor: "black",
                          color: "white",
                          padding: "5px",
                          borderRadius: "50%",
                        }}
                        onClick={() => {
                          handleImageDelete(image.ImageGalleryID);
                        }}
                      >
                        ✖
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-100">
                {item.isNew ? (
                  <div style={{ float: "right" }}>
                    <button
                      // disabled={editeAble}
                      className="btn btn-success"
                      onClick={() => AddNewOptionValue(item)}
                    >
                      Add Value{" "}
                    </button>
                  </div>
                ) : (
                  <div style={{ float: "right" }} className="mt-3">
                    <button
                      // disabled={editeAble}
                      className="btn btn-success"
                      onClick={(e) =>
                        submitOptionValueDetail(item.OptionValueID, item)
                      }
                    >
                      Save{" "}
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        })}

      <Modal
        toggle={() => setshowModal(!showModal)}
        isOpen={showModal}
        size="lg"
      >
        <ModalHeader toggle={() => setshowModal(!showModal)}>
          Custom Value
        </ModalHeader>
        <ModalBody>
          <div className="row">
            {SubCategoryVariants.length > 0 &&
              SubCategoryVariants.map((option) => (
                <>
                  <div className="col-6">
                    <div key={option.OptionName}>
                      <label>
                        {option.OptionName} <RequiredField />
                      </label>

                      {/* <h3></h3> */}
                      <select
                        className="form-control"
                        onChange={(e) =>
                          handleOptionChange(option.OptionName, e.target.value)
                        }
                      >
                        <option
                          value={""}
                          disabled
                          aria-label="Default select example"
                          selected
                        >
                          Select Available Option
                        </option>
                        {Object.keys(optionData).length > 0 &&
                          optionData && optionData[option.OptionName] && optionData[option.OptionName].map((item) => (
                            <option
                              key={item.SubCategoryVariantValueID}
                              value={item.VariantValue}
                            >
                              {item.VariantValue}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </>
              ))}

            {/* {optionWithValues.map((option) => {
              return (
                <OptionValueField
                  option={option}
                  setModalButton={setModalButton}
                  handleDropdownChange={handleDropdownChange}
                  setSelectedOptions={setSelectedOptions}
                  selectedOptions={selectedOptions}
                />
              );
            })} */}
            <div className="col-6">
              <label>
                SKU <RequiredField />
              </label>
              <input className="form-control" type="text" id="variantSKU" />
            </div>

            <div className="col-6">
              <label>
                Inventory <RequiredField />
              </label>
              <input
                // disabled={editeAble}
                className="form-control"
                type="number"
                min={0}
                id="variantInventory"
              />
            </div>
            <div className="col-6">
              <label>
                Available Inventory <RequiredField />
              </label>
              <input
                // disabled={editeAble}
                className="form-control"
                type="number"
                min={0}
                id="variantAvailableInventory"
              />
            </div>

            <div className="col-6">
              <label>
                Total Price <RequiredField />
              </label>
              <input
                // disabled={editeAble}
                className="form-control"
                type="number"
                defaultValue={0}
                min={0}
                id="variantTotalPrice"
              />
            </div>
            <div className="col-6">
              <label>
                Unit Price <RequiredField />
              </label>
              <input
                // disabled={editeAble}
                className="form-control"
                type="number"
                defaultValue={0}
                min={0}
                id="variantUnitPrice"
              />
            </div>
            <div className="col-6">
              <label>
                Price <RequiredField />
              </label>
              <input
                // disabled={editeAble}
                className="form-control"
                type="number"
                defaultValue={0}
                id="variantPrice"
                onChange={(e) => setModalPrice(e.target.value)}
              />
              <Alert color="success" className="p-1 m-1">
                Total Price ={" "}
                {`${Currency}  ${parseFloat(Price ? Price : 0) +
                  parseInt(ModalPrice ? ModalPrice : 0)
                  } ,this amount is sum of base price of this product and current variation value pricess`}{" "}
              </Alert>
            </div>
            <div className="col-6">
              <label>
                Images <RequiredField />
              </label>
              <input
                // disabled={editeAble}
                className="form-control"
                type="file"
                id="variantImage"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={() => CreateNewValue()}
            // disabled={ModalButton}
            disabled={disableBtn}
          >
            {disableBtn ? (
              <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              <span>Add Value</span>
            )}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ProductVariants;
