import axios from "axios";
import Endpoint from "./../../Utils/Endpoint";

// Define BanglaBazarApi as an axios instance
const BanglaBazarApi = axios.create({
  baseURL: Endpoint,
});

BanglaBazarApi.interceptors.request.use(function (config) {
  // Set headers dynamically from localStorage
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  config.headers.region = localStorage.getItem("region");
  config.headers.currencyCode = localStorage.getItem("currency");
  return config;
});

export default BanglaBazarApi;

// Function to fetch and set user country details
// async function getUserCountry() {
//   try {
//     const response = await axios.get("https://geolocation-db.com/json/");
//     let country = response.data.country_name.toLowerCase();

//     localStorage.setItem("country", country);

//     // Set region, currency, and iso based on country
//     if (country === "bangladesh") {
//       localStorage.setItem("region", "Bangladesh");
//       localStorage.setItem("currency", "BDT");
//       localStorage.setItem("iso", "bd");
//     } else if (country === "pakistan") {
//       localStorage.setItem("region", "pakistan");
//       localStorage.setItem("currency", "PKR");
//       localStorage.setItem("iso", "pk");
//     } else {
//       localStorage.setItem("region", "United States");
//       localStorage.setItem("currency", "USD");
//       localStorage.setItem("iso", "US");
//     }

//     // Make an API call using BanglaBazarApi
//     try {
//       const apiResponse = await BanglaBazarApi.get("/some-endpoint");
//       console.log("API Response: ", apiResponse.data);
//       // Handle the response
//     } catch (apiError) {
//       console.error("API Error: ", apiError);
//       // Handle the error
//     }
//   } catch (error) {
//     console.error(error);
//   }
// }
