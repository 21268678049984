// export const WebUrl = "http://192.168.1.64:3001";
// var Endpoint = "http://192.168.1.64:3001";
// export const Host_Name = "http://192.168.1.64:3001";

export const WebUrl = "https://banglabazar.com";

var Endpoint = "https://test.api.banglabazar.com";

//var Endpoint = "https://45ec-2407-aa80-126-6902-ac8c-91ce-e654-c602.ngrok-free.app";
//var Endpoint = "http://192.168.53.56:3001";
//var Endpoint = "http://localhost:3001";
//var Endpoint = "http://192.168.100.50:3001";
//var Endpoint = "http://192.168.100.94:3001";
// var Endpoint = "http://192.168.100.50:3001";
//  var Endpoint = "https://banglabazar.com";
//  var Endpoint = "http://192.168.100.50:3001";

export const Host_Name = "https://banglabazar.com";
export default Endpoint;

//"http://18.220.235.3:3001"
//"https://api.banglabazar.com"
//http://192.168.2.119:4000
