import { WebsiteHeader } from "./Layout/Header";
import prod1 from "../../../assets/images/prod1.png";
import prod2 from "../../../assets/images/prod2.png";
import prod3 from "../../../assets/images/prod3.png";
import prod4 from "../../../assets/images/prod4.png";
import prod5 from "../../../assets/images/prod5.png";

import "./product.css";

import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Collapse,
  List,
  Form,
} from "reactstrap";

import { Breadcrumb } from "antd";
import { Button, Modal } from "antd";

import { NewsLetter } from "./Layout/NewsLetter";
import { Footer } from "./Layout/Footer";
import { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import Endpoint from "./../../../Utils/Endpoint";
import axios from "axios";
import firetoast from "./../../../Helpers/FireToast";
import Loading from "../../../Utils/Loading";
import { Trending } from "./Layout/Trending";
import TopRatedProducts from "./Layout/TopRatedProducts";
import { useDispatch, useSelector } from "react-redux";
import BanglaBazarApi from "./../../Api/BanglaBazarApi";
import Product_Details_VendorDetails from "./Product Detail Components/PD_VendorDetails";
import Product_Detail_ProductImages from "./Product Detail Components/PD_ProductImages";
import ProductDetailRatingAndReviews from "./Product Detail Components/PD_RatingAndReviews";
import { getProductsDetailsById } from "./../../../Actions/ProductsAction";
import { CurrentUser } from "../../../Helpers/Auth";
import RemoveItemFromWishList from "./../../Api/RemoveItemFromWishList";
import ProductDetail_VariantValues from "./Product Detail Components/PD_VariantValueList";
import { AddCartItems, GetCartItems } from "./../../../Actions/CartAction";
import MaintainUnAuthCart from "./../../../Helpers/MaintainUnAuthCart";
import ProductReturnPolicy from "./Product Detail Components/PD_ReturnPolicy";
import ProductInstrunctions from "./Product Detail Components/PD_instructions";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { toast } from "react-toastify";
import { Cg } from "react-flags-select";

function ProductDetails() {
  const dispatch = useDispatch();
  const history = useHistory();

  const state = useSelector((state) => state);
  // console.log(state);
  const [tab, setTab] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [Business, setBusiness] = useState(null);
  const [Store, setStore] = useState(null);
  const { productID } = useParams();
  const [productDetail, setProductDetail] = useState(null);
  const [businessDetails, setbusinessDetails] = useState(null);
  const [VariantDetails, setVariantDetails] = useState([]);
  const [MainImage, setMainImage] = useState(null);
  const [AverageRatingAndReviews, setAverageRatingAndReviews] = useState(null);
  const [RatingCounts, setRatingCounts] = useState(null);
  const [UserReviewsAndRating, setUserReviewsAndRating] = useState([]);
  const { productsDetailById, currentIPv4 } = state;
  const [modal2Open, setModal2Open] = useState(false);
  const [counter, setCounter] = useState(1);
  const [CurrentlyActive, setCurrentlyActive] = useState(null);
  const [UserID, setUserId] = useState(-1);
  const [inWishList, setinWishList] = useState(false);
  const [SelectedValues, setSelectedValues] = useState({});
  const [activeVariantValue, setActiveVariantValue] = useState("");
  const [AllCountries, setAllCountries] = useState([]);
  const [variantOptionValues, setVariantOptionValues] = useState({});
  const [variantOptionValuesTwo, setVariantOptionValuesTwo] = useState([]);
  const [selectedValues, setSelectedValuess] = useState({});
  const [matchingVariant, setMatchingVariant] = useState(null);
  const [productVariants, setProductVariants] = useState([]);

  console.log(selectedValues, "selectedValues");
  console.log(matchingVariant, "matchingVariant");
  console.log(productVariants, "productVariants");
  console.log(variantOptionValues, "variantOptionValues");
  console.log(variantOptionValuesTwo, "variantOptionValuesTwo");
  // const [CartItemStatus, setCartItemStatus] = useState(false);
  // AddCartItems

  const shareUrl = "https://example.com"; // Replace this with your actual link to share
  const title = "Check out this awesome product!";
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let getTotalPrice = () => {
    let array = SelectedValues;
    let indexes = Object.keys(SelectedValues);

    let sum = parseFloat(productDetail.Price) + 0.0;
    for (let i = 0; i < indexes.length; i++) {
      sum += parseFloat(array[indexes[i]].VariantPrice);
    }
    return sum;
  };

  let setInventoryMinCount = () => {
    let array = SelectedValues;
    let indexes = Object.keys(SelectedValues);

    let inventories = [];
    for (let i = 0; i < indexes.length; i++) {
      inventories.push(array[indexes[i]].AvailableInventory);
    }
    let count = Math.min(...inventories);
    return count;
  };

  var getCountries = async () => {
    try {
      var response = await BanglaBazarApi.get(
        Endpoint + "/api/location/get-deliveryAllowedCountries"
      );
      var list = response.data.Countries;
      var temp = [];

      for (let i = 0; i < list.length; i++) {
        temp.push(list[i]["Country"]);
      }
      setAllCountries(temp);
    } catch (e) {
      // console.log(e);
    }
  };

  // getVariantOptionDetails("899")
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    let id = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).UserID
      : -1;
    id !== -1 ? setUserId(id) : setUserId(-1);
    window.scrollTo({ top: 0, behavior: "smooth" });

    dispatch(getProductsDetailsById(productID, id));

    if (productsDetailById.loading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setProductDetail(productsDetailById.data.ProductDetail);
      setbusinessDetails(productsDetailById.data.BuisnessDetail);
      setVariantDetails(productsDetailById.data.VariantDetails);

      setMainImage(productsDetailById.data.MainImage);
      setAverageRatingAndReviews(
        productsDetailById.data.ProductAverageRatingAndReviews
      );
      setRatingCounts(productsDetailById.data.ProductRatingCount);
      setUserReviewsAndRating(
        productsDetailById.data.UsersProductReviewAndRating
      );
      // console.log(productsDetailById);
      setinWishList(productsDetailById.data.inWishList);
      getActiveVariantValue();
    }
    if (productsDetailById.error) {
      return firetoast(
        "Something went wrong while fething product details",
        "default-errors"
      );
    }
    getCountries();
  }, [dispatch, productID, CurrentUser]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (productsDetailById.loading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      console.log(productsDetailById, "productsDetailById");

      if (!productsDetailById.data.variantOptionValues) {
        console.log("ifffffffffffffffffff");
        return;
      }
      setProductDetail(productsDetailById.data.ProductDetail);
      setbusinessDetails(productsDetailById.data.BuisnessDetail);
      setVariantDetails(productsDetailById.data.VariantDetails);

      setMainImage(productsDetailById.data.MainImage);
      setAverageRatingAndReviews(
        productsDetailById.data.ProductAverageRatingAndReviews
      );
      console.log(
        productsDetailById,
        "productsDetailById.data.variantOptionValues"
      );
      setVariantOptionValues(productsDetailById.data.variantOptionValues);
      setVariantOptionValuesTwo(productsDetailById.data.variantOptionValues);
      setRatingCounts(productsDetailById.data.ProductRatingCount);
      setUserReviewsAndRating(
        productsDetailById.data.UsersProductReviewAndRating
      );
      setinWishList(productsDetailById.data.inWishList);

      getActiveVariantValue();
      // Set initial values for selectedValues
      const initialSelectedValues = {};

      // Set the initial value for each variant based on the first option value in variantOptionValues
      for (const variantName in productsDetailById.data.variantOptionValues) {
        if (
          productsDetailById.data.variantOptionValues[variantName].length > 0
        ) {
          const initialOptionValue =
            productsDetailById.data.variantOptionValues[variantName][0];
          initialSelectedValues[variantName] = initialOptionValue.OptionValueID;
        }
      }

      setSelectedValuess(initialSelectedValues);
      // const initialSelectedValues = {};

      // Choose the first index of the first object in variantOptionValues
      // Iterate through variantOptionValues to choose the first available option for each variant
      // Choose the first available option for the first variant
      const firstVariantName = Object.keys(
        productsDetailById.data.variantOptionValues
      )[0];
      const firstOption =
        productsDetailById.data.variantOptionValues[firstVariantName][0];

      if (firstOption) {
        initialSelectedValues[firstVariantName] = firstOption.OptionValueID;
      }
      console.log(firstOption.OptionValueID[0], "initialSelectedValues");
      // setSelectedValuess(initialSelectedValues);

      // Set initial value for matchingVariant
      const initialSelectedValuesString = Object.values(
        initialSelectedValues
      ).join(",");
      const initialSortedSelectedValues = initialSelectedValuesString
        .split(",")
        .map(Number)
        .sort();
      const initialSortedSelectedValuesString =
        initialSortedSelectedValues.join(",");
      const initialMatchingVariant =
        productsDetailById.data.VariantDetails.find((variant) => {
          const sortedOptionValueID = variant.OptionValueID.split(",")
            .map(Number)
            .sort();
          return (
            sortedOptionValueID.join(",") === initialSortedSelectedValuesString
          );
        });
      setMatchingVariant(initialMatchingVariant);
    }
    if (productsDetailById.error) {
      return firetoast(
        "Something went wrong while fething product details",
        "default-errors"
      );
    }
  }, [dispatch, productsDetailById, productID]);
  // useEffect(async () => {
  //   await getActiveVariantValue();
  // }, [VariantDetails]);
  useEffect(() => {
    dispatch(GetCartItems());
  }, [dispatch, productID, state.removeCartItem]);

  useEffect(() => {
    setCounter(1);
    // setinWishList(false);
    setCurrentlyActive(null);
    setVariantDetails([]);
    setSelectedValues({});
  }, [productID]);

  var getActiveVariantValue = async () => {
    var data = VariantDetails;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        // let data_values = data[i].Values ? data[i].Values : [];
        let data_values = data[i] ? data[i] : [];

        for (let j = 0; j < data_values.length; j++) {
          var temp = data_values[j].MainImage;
          if (temp === "Y") {
            setCurrentlyActive(data_values[j]);
            setActiveVariantValue(`VVI${data_values[j].OptionValueID}`);
          }
        }
      }
    }
  };

  var getProductDetails = async () => {
    dispatch(getProductsDetailsById(productID, UserID));
  };

  var updateCounter = async (val) => {
    if (Object.keys(SelectedValues).length !== VariantDetails.length) {
      return firetoast(
        "Please select available options in all available variations to update the count",
        "info",
        4000,
        "top-center"
      );
    }
    let MinCount = parseInt(setInventoryMinCount());
    if (MinCount) {
      if (MinCount > 0) {
        if (val === "-") {
          if (counter > 1) {
            setCounter(counter - 1);
          }
        } else {
          if (counter < MinCount) {
            var temp_count = counter + 1;
            setCounter(temp_count);
          }
        }
      }
    }
  };

  const info = () => {
    Modal.info({
      title: "Express Delivery",
      content: (
        <div>
          <p>
            If you place your order before 12 p.m. Bangladesh time, Selected
            Products will be eligible for Express Delivery (On Day Delivery) at
            this moment. If you place the order after 12 p.m., you will get the
            parcel tomorrow
          </p>
        </div>
      ),
      onOk() {
        history.push(`/my-cart`);
      },
    });
  };

  const addToCartInfo = () => {
    Modal.info({
      title: "Express Delivery",
      content: (
        <div>
          <p>
            If you place your order before 12 p.m. Bangladesh time, Selected
            Products will be eligible for Express Delivery (On Day Delivery) at
            this moment. If you place the order after 12 p.m., you will get the
            parcel tomorrow
          </p>
        </div>
      ),
      onOk() {},
    });
  };

  var addToWishList = async () => {
    try {
      var response = await BanglaBazarApi.post(
        `${Endpoint}/api/wish-list/addUserWishList`,
        {
          ProductID: productID,
          // ProductVariantCombinationID:
          //   CurrentlyActive.ProductVariantCombinationID,
        }
      );
      if (response.data.status) {
        setinWishList(true);
        // firetoast("Added to Wishlist", "success", 3000, "top-right");
      } else {
        var { message, error } = response.data;
        setinWishList(false);
        firetoast(message || error, "default-error");
      }
    } catch (e) {
      setinWishList(false);
      firetoast("Something went wrong!", "default-error");
    }
  };

  var removeFromWishlist = async (id) => {
    if (RemoveItemFromWishList(id)) {
      setinWishList(false);
    } else {
      firetoast("Something went wrong", "default-error");
      setinWishList(true);
    }
  };

  var BuyNow = async () => {
    if (!Object.keys(matchingVariant).length > 0) {
      // if (!Object.keys(matchingVariant).length > 0) {
      return firetoast(
        "Please select available options in order to proceed",
        "info",
        4000,
        "top-center"
      );
    } else if (!Object.keys(matchingVariant)) {
      return firetoast(
        "Please select available options in all available variations",
        "info",
        4000,
        "top-center"
      );
    } else {
      let dataObject = {
        SessionID: localStorage.getItem("accessToken"),
      };

      let ProductObj = {
        ProductID: productID,
        Quantity: counter,
      };
      let ProductObjCopy = {
        ProductID: productID,
        Quantity: 1,
      };
      let ProductDetail = [];
      let indexes = Object.keys(matchingVariant);
      let SelectedObj = matchingVariant;
      let ProductVariantCombinationDetail = [];
      let d = 1;
      for (let i = 0; i < d; i++) {
        ProductVariantCombinationDetail.push({
          ProductVariantCombinationID:
            matchingVariant && matchingVariant.ProductVariantCombinationID,
        });
      }

      if (CurrentUser) {
        ProductObj.ProductVariantCombinationDetail =
          ProductVariantCombinationDetail;
        ProductDetail.push(ProductObj);
        dataObject.ProductDetail = ProductDetail;
        dispatch(AddCartItems(dataObject));
        if (productDetail["ExpressDelivery"] === "Y") {
          info();
          // toast("If you place your order before 12 p.m. Bangladesh time, Selected Products will be eligible for Express Delivery (On Day Delivery) at this moment. If you place the order after 12 p.m., you will get the parcel tomorrow")
          // setTimeout(() => {
          //   history.push(`/my-cart`);
          // }, 4000);
        } else {
          setTimeout(() => {
            history.push(`/my-cart`);
          }, 1000);
        }
      } else {
        // ProductObjCopy.ProductVariantCombinationDetail =
        //   ProductVariantCombinationDetail;
        // ProductDetail.push(ProductObjCopy);
        // dataObject.ProductDetail = ProductDetail;
        ProductObjCopy.ProductVariantCombinationDetail =
          ProductVariantCombinationDetail;
        ProductDetail.push(ProductObjCopy);
        dataObject.ProductDetail = ProductDetail;
        ProductObjCopy["ProductDetail"] = productDetail;
        ProductObjCopy["ProductCombinations"] = matchingVariant;

        MaintainUnAuthCart(dataObject);
      }
    }
    dispatch(GetCartItems());
  };

  var AddItemToCart = async () => {
    // console.log(cartItems);
    const { getCartItem } = state;
    const cartItems =
      (getCartItem &&
        getCartItem["data"] &&
        getCartItem["data"]["productCartList"] &&
        getCartItem["data"]["productCartList"]) ||
      [];
    console.log(getCartItem, "getCartItem");
    // return
    console.log("================>", matchingVariant);

    // return
    if (!Object.keys(matchingVariant).length > 0) {
      return firetoast(
        "Please select available options in order to proceed",
        "info",
        4000,
        "top-center"
      );
    } else if (!Object.keys(matchingVariant)) {
      return firetoast(
        "Please select available options in all available variations",
        "info",
        4000,
        "top-center"
      );
    } else {
      let dataObject = {
        SessionID: localStorage.getItem("accessToken"),
      };

      let ProductObj = {
        ProductID: productID,
        Quantity: counter,
      };
      let ProductObjCopy = {
        ProductID: productID,
        Quantity: 1,
      };
      let ProductDetail = [];
      let indexes = Object.keys(matchingVariant);
      let SelectedObj = matchingVariant;
      let ProductVariantCombinationDetail = [];
      let d = 1;
      for (let i = 0; i < d; i++) {
        ProductVariantCombinationDetail.push({
          ProductVariantCombinationID:
            matchingVariant && matchingVariant.ProductVariantCombinationID,
          // SelectedObj[indexes[i]].ProductVariantCombinationID,
        });
      }

      if (CurrentUser) {
        var temp_cart = [...cartItems];
        // console.log(temp_cart);

        temp_cart.push(productDetail);
        ProductObj.ProductVariantCombinationDetail =
          ProductVariantCombinationDetail;
        ProductDetail.push(ProductObj);
        dataObject.ProductDetail = ProductDetail;
        // let combinationId=matchingVariant && matchingVariant.ProductVariantCombinationID
        // console.log(combinationId,"combinationIdcombinationId")
        // dataObject.ProductVariantCombinationDetail=[combinationId]
        // console.log(temp_cart);
        //check before proceed
        var SelectedProductsArray = [];
        var GlobalShippingStatus = [];
        var ShippingAvailableStatus = [];
        var ShippingCities = [];
        for (let x in temp_cart) {
          if (
            temp_cart[x].ProductCountry === 16 ||
            temp_cart[x].ProductCountry === "16"
          ) {
            temp_cart[x].ProductCountry = "Bangladesh";
          }
          if (
            temp_cart[x].ProductCountry === 226 ||
            temp_cart[x].ProductCountry === "226"
          ) {
            temp_cart[x].ProductCountry = "United States";
          }
          SelectedProductsArray.push(temp_cart[x].ProductCountry);
          GlobalShippingStatus.push(temp_cart[x].ShippingGlobal);
          ShippingAvailableStatus.push(temp_cart[x].ShippingAvailable);
          ShippingCities.push(temp_cart[x].City);
        }
        const allEqual = (arr) => arr.every((v) => v === arr[0]);
        var CheckEqualCountries = allEqual(SelectedProductsArray);
        var CheckAllGlobalShipping = allEqual(GlobalShippingStatus);
        var CheckAllShippingAvailable = allEqual(ShippingAvailableStatus);
        var CheckAllShippingCities = allEqual(ShippingCities);

        // console.log("CheckEqualCountries", CheckEqualCountries);
        // console.log("CheckAllGlobalShipping", CheckAllGlobalShipping);
        // console.log("CheckAllShippingAvailable", CheckAllShippingAvailable);
        // console.log("CheckAllShippingCities", CheckAllShippingCities);

        var err_status = false;
        var err_message = "";
        if (CheckEqualCountries) {
          if (CheckAllGlobalShipping) {
            //Global Shipping  = y
            if (CheckAllShippingAvailable) {
              //Global Shipping  =y  && Shipping to other cities = y
              // //1-case
              // console.log(
              //   "/Global Shipping  =y  && Shipping to other cities = y"
              // );
            }
          } else {
            if (CheckAllShippingAvailable) {
              //5-case
              // console.l/og("//5-case");
            } else {
              // 6-case
              if (CheckAllShippingCities) {
                //7-case
                // console.log("//7-case");
              } else {
                //8-case
                // console.log("8");

                err_status = true;
                err_message =
                  "This product is not available to the same place of the product you have previously added in your shopping cart. If product availability location is not your desire delivery location then please remove the product from your shopping cart";
                cartItems.pop();
                return firetoast(err_message, "error", 5000, "top-center");
              }
            }
          }
        } else {
          return firetoast(
            "This product’s payment cannot be process at the same time with previously add product in your shopping cart. Please pay your previously added product from your shopping cart then make a separate purchase for this product or remove previously added product from your shopping cart then add this product to your cart. ",
            "error",
            6000,
            "top-right"
          );
        }
        cartItems.pop();
        dispatch(AddCartItems(dataObject));

        // return
      } else {
        // ProductObjCopy.ProductVariantCombinationDetail =
        //   ProductVariantCombinationDetail;
        // ProductDetail.push(ProductObjCopy);
        // dataObject.ProductDetail = ProductDetail;
        ProductObjCopy.ProductVariantCombinationDetail =
          ProductVariantCombinationDetail;
        ProductDetail.push(ProductObjCopy);
        dataObject.ProductDetail = ProductDetail;
        ProductObjCopy["ProductDetail"] = productDetail;
        ProductObjCopy["ProductCombinations"] = matchingVariant;
        MaintainUnAuthCart(dataObject);
      }
    }
    if (productDetail["ExpressDelivery"] === "Y") {
      addToCartInfo();
    }
    firetoast("Item added to cart", "success", 2000, "top-center");
    dispatch(GetCartItems());
  };

  var getCartButton = () => {
    var local_region = localStorage.getItem("region");

    if (productDetail["ShippingGlobal"] === "Y") {
      return (
        <div className="row mt-4">
          <div className="col-6">
            <button
              className="btn btn-light text-default w-100"
              onClick={() => AddItemToCart()}
            >
              Add To Cart
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-success w-100"
              onClick={() => {
                BuyNow();
              }}
            >
              Buy Now
            </button>
          </div>
        </div>
      );
    } else {
      if (local_region === productDetail.ProductCountry) {
        return (
          <div className="row mt-4">
            <div className="col-6">
              <button
                className="btn btn-light text-default w-100"
                onClick={() => AddItemToCart()}
              >
                Add To Cart
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-success w-100"
                onClick={() => {
                  BuyNow();
                }}
              >
                Buy Now
              </button>
            </div>
          </div>
        );
      } else {
        return (
          <div className="text-orange mt-2">{`This product is only available for shipping in ${productDetail.ProductCountry}`}</div>
        );
      }
    }
  };

  var getCartButtonDisabled = () => {
    // Define a function to handle the click event
    const handleClick = () => {
      firetoast("This Product is out of Stock", "info", 5000, "top-center");
    };

    return (
      <div className="row mt-4">
        <div className="col-6">
          <button
            className="btn btn-light text-default w-100"
            title="Product not available"
            onClick={handleClick} // Add onClick event handler
          >
            Add To Cart
          </button>
        </div>
        <div className="col-6">
          <button
            className="btn btn-success w-100"
            title="Product not available"
            onClick={handleClick} // Add onClick event handler
          >
            Buy Now
          </button>
        </div>
      </div>
    );
  };

  // console.log(variantOptionValues , "productDetail")
  // const [variantOptionValues, setVariantOptionValues] = useState({});

  //   const handleSelectChange = (variantName, optionValueID) => {
  //     const updatedSelectedValues = { ...selectedValues, [variantName]: optionValueID };
  //     setSelectedValuess(updatedSelectedValues);

  //     // Build the selected values string
  //     const selectedValuesString = Object.values(updatedSelectedValues).join(',');
  // console.log(selectedValuesString, "selectedValuesString")
  //     // Find a matching variant in VariantDetails based on the selected values
  //     const matchingVariant = VariantDetails.find((variant) => variant.OptionValueID === selectedValuesString);
  //     setMatchingVariant(matchingVariant);
  //   };

  //VARIANT FUNCTION STARTING //
  const [isVariantLoading, setIsVariantLoading] = useState(false);

  const handleSelectChange = async (variantName, optionValueId) => {
    console.log("valueId", optionValueId);
    console.log("variantName", variantName);

    setIsVariantLoading(true);

    await setSelectedValues((prevState) => ({
      ...prevState,
      [variantName]: optionValueId,
    }));

    console.log("sewsa", selectedValues);

    // let color, size;

    // // Assign values to color and size based on selectedValues
    // Object.keys(selectedValues).forEach(key => {
    //   if (key === 'Color') {
    //     color = selectedValues[key];
    //   } else if (key === 'Size') {
    //     size = selectedValues[key];
    //   }
    // });

    // // Log the new value for the changed dropdown
    // //console.log(`New value for ${variantName}: ${optionValueId}`);

    // // Log the current value of color and size
    // console.log(`Current value for Color: ${color}`);
    // console.log(`Current value for Size: ${size}`);

    try {
      // const optionValueIDone = color;
      // const optionValueIDtwo = size;

      if (optionValueId === "default") {
        console.log("default me");
        const initialSelectedValues = {};

        // Set the initial value for each variant based on the first option value in variantOptionValues
        for (const variantName in variantOptionValuesTwo) {
          if (variantOptionValuesTwo[variantName].length > 0) {
            const initialOptionValue =
              productsDetailById.data.variantOptionValues[variantName][0];
            initialSelectedValues[variantName] =
              initialOptionValue.OptionValueID;
          }
        }

        setSelectedValuess(initialSelectedValues);
        // const initialSelectedValues = {};

        // Choose the first index of the first object in variantOptionValues
        // Iterate through variantOptionValues to choose the first available option for each variant
        // Choose the first available option for the first variant

        const firstVariantName = Object.keys(variantOptionValuesTwo)[0];
        const firstOption = variantOptionValuesTwo[firstVariantName][0];

        if (firstOption) {
          initialSelectedValues[firstVariantName] = firstOption.OptionValueID;
        }

        console.log(firstOption.OptionValueID[0], "initialSelectedValues");
        // setSelectedValuess(initialSelectedValues);

        // Set initial value for matchingVariant
        const initialSelectedValuesString = Object.values(
          initialSelectedValues
        ).join(",");
        const initialSortedSelectedValues = initialSelectedValuesString
          .split(",")
          .map(Number)
          .sort();
        const initialSortedSelectedValuesString =
          initialSortedSelectedValues.join(",");
        const initialMatchingVariant =
          productsDetailById.data.VariantDetails.find((variant) => {
            const sortedOptionValueID = variant.OptionValueID.split(",")
              .map(Number)
              .sort();
            return (
              sortedOptionValueID.join(",") ===
              initialSortedSelectedValuesString
            );
          });
        setMatchingVariant(initialMatchingVariant);
        return;
      }

      console.log("yarrrrrrrrrrrrrrrr");
      const firstOldKey = Object.keys(variantOptionValuesTwo)[0];
      // console.log(firstOldKey, "firstOldKey");
      const id = parseInt(optionValueId);
      const intermediateResult = [];
      // Filter the variants that have the provided OptionValueID
      const matchingVariants = VariantDetails.filter((variant) => {
        return variant.OptionValueID.split(",").map(Number).includes(id);
      });

      console.log(matchingVariants, "656 matchingVariants");

      if (!matchingVariants.length) {
        return "No matching variants found!";
      }
      // console.log(matchingVariants , "matchingVariants" )
      // Loop through each matching variant and retrieve the related options
      for (const variant of matchingVariants) {
        const relatedVariant = {};
        // adeel
        const relatedOptionValueIDs =
          variant.OptionValueID.split(",").map(Number);
        for (const key in variantOptionValuesTwo) {
          for (const option of variantOptionValuesTwo[key]) {
            if (relatedOptionValueIDs.includes(option.OptionValueID)) {
              if (!relatedVariant[key]) {
                relatedVariant[key] = [];
              }
              relatedVariant[key].push(option);
            }
          }
        }
        intermediateResult.push(relatedVariant);
      }
      console.log(intermediateResult, "intermediateResult");

      const consolidatedResult = consolidateData(intermediateResult);
      console.log(consolidatedResult, "consolidatedResult");
      return consolidatedResult;

      var updatedSelectedValues = {};

      async function consolidateData(data) {
        const result = {};
        for (const entry of data) {
          for (const key in entry) {
            if (!result[key]) {
              result[key] = [];
            }
            for (const item of entry[key]) {
              if (
                !result[key].some((e) => e.OptionValueID === item.OptionValueID)
              ) {
                result[key].push(item);
              }
            }
          }
        }
        console.log(result, "pppp result");
        // Extract all keys from newData excluding the first key
        const newDataKeysExcludingFirst = Object.keys(result).slice(1);
        // variantOptionValues
        // new
        // setVariantOptionValues(result)
        // return result;
        const mergedData = {
          [firstOldKey]: variantOptionValues[firstOldKey],
          ...newDataKeysExcludingFirst.reduce((acc, key) => {
            acc[key] = result[key];
            return acc;
          }, {}),
        };

        // setVariantOptionValues(mergedData);
        console.log(mergedData, "mergedData");
        console.log(Object.keys(mergedData)[0] != variantName);
        console.log(Object.keys(mergedData)[0], "MY KEY");
        let state = {};

        // if (Object.keys(mergedData)[0] === variantName) {
        //   console.log("merge 1 ")
        //   Object.keys(mergedData).forEach((key) => {
        //     if (key !== variantName) {
        //       // Assuming there's always at least one element in the array
        //       // and you want to take the OptionValueID from the first one.
        //       state[key] = mergedData[key][0].OptionValueID;
        //     }
        //   });
        //   updatedSelectedValues = {
        //     ...state,
        //     // result
        //     [variantName]: optionValueId,
        //   };
        //   setSelectedValuess(updatedSelectedValues);
        // } else {
        console.log("merge 2 ");

        updatedSelectedValues = {
          ...selectedValues,
          [variantName]: optionValueId,
        };
        setSelectedValuess(updatedSelectedValues);
        // }

        console.log(updatedSelectedValues, "updatedSelectedValues 2");
        console.log(variantName, "updatedSelectedValue variantNames 2");
        // Build the selected values string
        const selectedValuesString = Object.values(updatedSelectedValues).join(
          ","
        );
        // Sort the selected values
        const sortedSelectedValues = selectedValuesString
          .split(",")
          .map(Number)
          .sort();
        const sortedSelectedValuesString = sortedSelectedValues.join(",");
        console.log(sortedSelectedValuesString, "sortedSelectedValuesString");
        // Find a matching variant in VariantDetails based on the sorted selected values
        const matchingVariant = VariantDetails.find((variant) => {
          const sortedOptionValueID = variant.OptionValueID.split(",")
            .map(Number)
            .sort();
          return sortedOptionValueID.join(",") === sortedSelectedValuesString;
        });

        console.log(matchingVariant, "matchingVariant new");
        setMatchingVariant(matchingVariant);

        //Log the current values for dynamically rendered dropdowns
        // Convert values in updatedSelectedValues to numbers
        const parsedSelectedValues = {};

        Object.keys(updatedSelectedValues).forEach((key) => {
          // Use parseInt to convert the value to a number
          parsedSelectedValues[key] = parseInt(updatedSelectedValues[key], 10);
        });

        // Display the current values after conversion
        Object.keys(parsedSelectedValues).forEach((key) => {
          console.log(`Current value for ${key}: ${parsedSelectedValues[key]}`);
        });

        // Prepare option values for API request
        const optionValuesForApi = {};

        Object.keys(parsedSelectedValues).forEach((key, index) => {
          const dynamicKey = `optionValueID${index + 1}`;
          optionValuesForApi[dynamicKey] = parsedSelectedValues[key];
        });

        console.log("optionValuesForApi", optionValuesForApi);

        var response = await BanglaBazarApi.post(
          `${Endpoint}/api/product/get-productAllVariantDetails/${productID}`,
          optionValuesForApi
        );

        setIsVariantLoading(false);

        setVariantOptionValues(response.data.variantOptionValues);
      }

      // console.log(data, "inder data");
      // setProductVariants(data);
      // setSelectedValuess({
      //   ...selectedValues,
      //   [variantName]: optionValueId,
      // });
    } catch (e) {
      console.log(e, "error");
      setIsVariantLoading(false);
    }
  };

  //VARIANT FUNCTION ENDING //

  const handleSelectChange1 = (variantName, optionValueID) => {
    // const updatedSelectedValues = {
    //   ...selectedValues,
    //   [variantName]: optionValueID,
    // };
    // setSelectedValuess(updatedSelectedValues);
    // // Build the selected values string
    // const selectedValuesString = Object.values(updatedSelectedValues).join(",");
    // // Sort the selected values
    // const sortedSelectedValues = selectedValuesString
    //   .split(",")
    //   .map(Number)
    //   .sort();
    // const sortedSelectedValuesString = sortedSelectedValues.join(",");
    // console.log(sortedSelectedValuesString, "sortedSelectedValuesString");
    // // Find a matching variant in VariantDetails based on the sorted selected values
    // const matchingVariant = VariantDetails.find((variant) => {
    //   const sortedOptionValueID = variant.OptionValueID.split(",")
    //     .map(Number)
    //     .sort();
    //   return sortedOptionValueID.join(",") === sortedSelectedValuesString;
    // });
    // setMatchingVariant(matchingVariant);
    // console.log(data, "inder data");
    // setProductVariants(data);
    // setSelectedValuess({
    //   ...selectedValues,
    //   [variantName]: optionValueID,
    // });
  };

  // Initialize state with default values for each variant
  const [selectedValuesTwo, setSelectedValuesTwo] = useState(() => {
    const initialSelections = {};
    Object.keys(variantOptionValues).forEach((variantName) => {
      const defaultVariantValue =
        variantOptionValues[variantName].find(
          (option) => option.mainImages === "Y"
        )?.VariantValue || "";
      initialSelections[variantName] = defaultVariantValue;
    });
    return initialSelections;
  });

  // Handle select change
  const handleSelectChangeTwo = (variantName, newValue) => {
    console.log("variantName", variantName);
    console.log("newValue", newValue);

    setSelectedValues((prev) => ({
      ...prev,
      [variantName]: newValue,
    }));
  };

  // console.log(productDetail, "productDetail");

  // Track unique VariantValue values using a set
  const uniqueVariantValues = new Set();

  // Example function to initialize selectedValues with default options
  const initializeSelectedValues = () => {
    const initialSelectedValues = {};
    Object.keys(variantOptionValues).forEach((variantName) => {
      const defaultOption = variantOptionValues[variantName].find(
        (option) => option.mainImages === "Y"
      );
      initialSelectedValues[variantName] = defaultOption
        ? defaultOption.OptionValueID
        : "";
    });
    return initialSelectedValues;
  };

  // Call this function to initialize selectedValues state
  const [selectedValuesCurrent, setSelectedValuesCurrent] = useState(
    initializeSelectedValues()
  );

  return (
    <>
      <WebsiteHeader />

      {productsDetailById.loading ? (
        <Loading text="Composing Data..." />
      ) : (
        <div className="container">
          <>
            {/* {JSON.stringify(productDetail)} */}
            {productDetail && (
              <div className="pt-2 pb-0">
                {/* <Breadcrumb listTag="div">
                <BreadcrumbItem
                  href="/"
                  tag="a"
                  className="td-none"
                  style={{ color: "#B1B1B1" }}
                >
                  Home
                </BreadcrumbItem>
                <BreadcrumbItem
                  href="#"
                  tag="a"
                  className="td-none"
                  style={{ color: "#787878" }}
                >
                  Category
                </BreadcrumbItem>
                <BreadcrumbItem
                  href="#"
                  tag="a"
                  className="td-none"
                  style={{ color: "#787878" }}
                >
                  Product
                </BreadcrumbItem>
                </Breadcrumb> */}

                <Breadcrumb
                  separator=">"
                  items={[
                    {
                      title: "Home",
                      href: "/",
                    },
                    {
                      title: productDetail.Category,
                      href: `search-products/category/${productDetail.CategoryID}`,
                    },
                    {
                      title: productDetail.SubCategory,
                      href: `search-products/subcategory/${productDetail.SubCategoryID}`,
                    },
                    {
                      title: productDetail.Title,
                    },
                  ]}
                />
              </div>
            )}

            {productDetail && (
              <>
                <div className="row mt-5">
                  <div className="col-xl-8 col-md-12 col-sm-12 mt-3 m-auto">
                    <div className="row mt-2">
                      <div className="col-lg-6 col-md-7 col-sm-12">
                        {MainImage === null ||
                        MainImage === undefined ||
                        MainImage === "" ? (
                          <h1>No Data</h1>
                        ) : (
                          <Product_Detail_ProductImages
                            MainImage={MainImage}
                            matchingVariant={matchingVariant}
                          />
                        )}
                      </div>
                      <div className="col-lg-6 col-md-5 col-sm-12">
                        <div className="d-flex justify-content-between align-items-baseline desktop-margin-top">
                          <h2 className="ftw-400">
                            {productDetail.Title}
                            {CurrentlyActive &&
                              ` - ${CurrentlyActive.VariantValue}`}
                          </h2>
                          <div style={{ display: "flex" }}>
                            <span>
                              <i
                                onClick={() => setModal2Open(true)}
                                className="fas fa-share-alt text-light-grey"
                                style={{
                                  fontSize: "18px",
                                  cursor: "pointer",
                                  margin: "10px",
                                }}
                              ></i>
                            </span>
                            <Modal
                              title="Share"
                              centered
                              open={modal2Open}
                              onOk={() => setModal2Open(false)}
                              onCancel={() => setModal2Open(false)}
                            >
                              <div>
                                {/* Share buttons (you can customize these with additional props if needed) */}
                                <FacebookShareButton
                                  url={window.location.href}
                                  quote={title}
                                >
                                  <FacebookIcon size={32} round />
                                </FacebookShareButton>
                                <TwitterShareButton
                                  url={window.location.href}
                                  title={title}
                                >
                                  <TwitterIcon size={32} round />
                                </TwitterShareButton>
                              </div>
                            </Modal>
                            {inWishList ? (
                              <span
                                style={{ margin: "10px" }}
                                onClick={() => removeFromWishlist(productID)}
                              >
                                <i
                                  className="fas fa-heart text-default"
                                  style={{
                                    fontSize: "18px",
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </span>
                            ) : (
                              <span
                                style={{ margin: "10px" }}
                                onClick={() => {
                                  if (CurrentUser) {
                                    addToWishList();
                                  } else {
                                    firetoast(
                                      "Login first to add this item in your wish list",
                                      "info",
                                      5000,
                                      "top-center"
                                    );
                                  }
                                }}
                              >
                                <i
                                  className="far fa-heart text-light-grey "
                                  style={{
                                    fontSize: "18px",
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="d-flex product-categories mt-4">
                          <div style={{ paddingLeft: "0px" }}>
                            <Link className="td-none" to="#">
                              {productDetail.Category}
                            </Link>
                          </div>
                          <div className="side-divider">
                            <Link className="td-none" to="#">
                              {productDetail.SubCategory}
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between product-ratings mt-4">
                          <div>
                            <span className="badge badge-warning">
                              {AverageRatingAndReviews &&
                              parseFloat(
                                AverageRatingAndReviews.ProductAverageRating
                              ).toFixed(1) === "NaN"
                                ? 0
                                : parseFloat(
                                    AverageRatingAndReviews.ProductAverageRating
                                  ).toFixed(1)}{" "}
                              <span>
                                <i className="fas fa-star text-white"></i>
                              </span>
                            </span>
                            <span className="product-rating-stat">
                              {AverageRatingAndReviews &&
                                AverageRatingAndReviews.TotalRating}{" "}
                              Ratings &{" "}
                              {AverageRatingAndReviews &&
                                AverageRatingAndReviews.Total_Reviews}{" "}
                              Reviews
                            </span>
                          </div>
                          <div>
                            <h6 className="ftw-400 text-danger">
                              {!matchingVariant ||
                              matchingVariant?.AvailableInventory === 0 ? (
                                <span
                                  style={{ textDecoration: "line-through" }}
                                >
                                  In Stock
                                </span>
                              ) : (
                                "In Stock"
                              )}
                            </h6>
                          </div>
                        </div>
                        <hr style={{ marginBottom: "8px" }} />
                        <div className="mt-2">
                          <span className="product-price">
                            <span> {productDetail.Currency} </span>
                            <p>
                              {console.log(
                                productDetail.Price,
                                "productDetail.Price"
                              )}{" "}
                            </p>

                            {matchingVariant &&
                              parseFloat(productDetail.Price) +
                                parseFloat(matchingVariant?.VariantPrice)}

                            {console.log(matchingVariant, "matchingVariant")}

                            {/* {Object.keys(SelectedValues).length > 0
                              ? parseFloat(getTotalPrice().toFixed(2)) * counter
                              : CurrentlyActive &&
                                `${
                                  parseFloat(
                                    parseInt(
                                      parseFloat(
                                        parseFloat(productDetail.Price) +
                                          parseFloat(
                                            CurrentlyActive.VariantPrice
                                          )
                                      ).toFixed(2)
                                    )
                                  ) * counter
                                }`} */}
                          </span>
                          {/* <span className="product-price-tag">& Free Returns</span> */}
                        </div>
                        <div className="mt-4">
                          <ul className="list-style-none product-desc-ul p-0">
                            {CurrentlyActive && (
                              <li>
                                <i className="fas fa-check text-default"></i>
                                {" SKU : "}
                                {CurrentlyActive.SKU}
                              </li>
                            )}
                            {/* <li>
                              <i className="fas fa-check text-default"></i> Nunc nec
                              porttitor turpis. In eu risus enim. In vitae
                              mollis elit.
                            </li>
                            <li>
                              <i className="fas fa-check text-default"></i> Nunc nec
                              porttitor turpis. In eu risus enim. In vitae
                              mollis elit.
                            </li> */}
                          </ul>
                        </div>
                        {CurrentUser && (
                          <div className="mt-4">
                            {/* {Object.keys(SelectedValues).length > 0 && ( */}
                            <div className="d-flex product-quantity">
                              <div>Quantity: </div>
                              <div>
                                <button
                                  className="counter-btn minus-btn"
                                  onClick={() => updateCounter("-")}
                                >
                                  -
                                </button>
                              </div>
                              <div className="product-quantity">{counter}</div>
                              <div>
                                <button
                                  className="counter-btn plus-btn"
                                  onClick={() => updateCounter("+")}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            {/* // )} */}
                          </div>
                        )}
                        <div className="mt-4">
                          <h5 className="text-default">Variations</h5>
                          <div>
                            <div>
                              {/* {variantOptionValues[variantName].map(
                                        (option) => (
                                         <>
                                        { 
                                         <option
                                            key={option.OptionValueID}
                                            value={option.OptionValueID}
                                            disabled={option.isdisabled}
                                            title={option.isdisabled ? "Product Not available" : ""}

                                          >
                                            {option.VariantValue}
                                          </option>}
                                         </>
                                        )
                                      )} */}

                              {Object.keys(variantOptionValues).map(
                                (variantName, index) => {
                                  // Use the state value for the current variant

                                  const currentValue =
                                    selectedValues[variantName];

                                  return (
                                    <div key={index}>
                                      <label className="fw-bold pt-2">
                                        {variantName}:
                                      </label>

                                      <select
                                        className="form-control"
                                        value={currentValue}
                                        onChange={(e) =>
                                          handleSelectChange(
                                            variantName,
                                            e.target.value
                                          )
                                        }
                                      >
                                        {variantOptionValues[variantName].map(
                                          (option) => (
                                            <option
                                              key={option.OptionValueID}
                                              value={option.OptionValueID}
                                              disabled={option.isdisabled}
                                              title={
                                                option.isdisabled
                                                  ? "Product Not available"
                                                  : ""
                                              }
                                            >
                                              {option.VariantValue}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  );
                                }
                              )}

                              {/* {Object.keys(variantOptionValues).map(
                                (variantName, index) => (
                                  <div key={index}>
                                    <label className="fw-bold pt-2">
                                      {variantName}:
                                    </label>
                                    <select
                                      className="form-control"
                                      // value={selectedValues[variantName] || ""}
                                      onChange={(e) =>
                                        handleSelectChange(
                                          variantName,
                                          e.target.value
                                        )
                                      }
                                    >
                                      {variantOptionValues[variantName].map(
                                        (option) => {
                                          // Check if the VariantValue is already encountered
                                          if (
                                            !uniqueVariantValues.has(
                                              option.VariantValue
                                            )
                                          ) {
                                            // If not encountered, add it to the set and render the option
                                            uniqueVariantValues.add(
                                              option.VariantValue
                                            );

                                            return (
                                              <option
                                                key={option.OptionValueID}
                                                value={option.OptionValueID}
                                                disabled={option.isdisabled}
                                                title={
                                                  option.isdisabled
                                                    ? "Product Not available"
                                                    : ""
                                                }
                                              >
                                                {option.VariantValue}
                                              </option>
                                            );
                                          }

                                          // If VariantValue is a duplicate, don't render the option
                                          return null;
                                        }
                                      )}
                                      
                                    </select>
                                  </div>
                                )
                              )} */}
                            </div>
                          </div>
                        </div>
                        {!matchingVariant ||
                        matchingVariant.AvailableInventory === 0
                          ? getCartButtonDisabled()
                          : getCartButton()}

                        {/* {currentIPv4.IP.country_name === "Pakistan" && (
                          <div className="row mt-4">
                            <div className="col-6">
                              <button
                                className="btn btn-light text-default w-100"
                                onClick={() => AddItemToCart()}
                              >
                                Add To Cart
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                className="btn btn-success w-100"
                                onClick={() => {
                                  BuyNow();
                                }}
                              >
                                Buy Now
                              </button>
                            </div>
                          </div>
                        )} */}
                      </div>
                    </div>
                    <div className="mt-5">
                      <Nav tabs>
                        <NavItem className="store-tabItem">
                          <NavLink
                            className={tab === 1 ? "active" : ""}
                            onClick={() => setTab(1)}
                          >
                            Description
                          </NavLink>
                        </NavItem>
                        <NavItem className="store-tabItem">
                          <NavLink
                            className={tab === 2 ? "active" : ""}
                            onClick={() => setTab(2)}
                          >
                            Vendor Info
                          </NavLink>
                        </NavItem>
                        <NavItem className="store-tabItem">
                          <NavLink
                            className={tab === 3 ? "active" : ""}
                            onClick={() => setTab(3)}
                          >
                            Customer Reviews
                          </NavLink>
                        </NavItem>
                        <NavItem className="store-tabItem">
                          <NavLink
                            className={tab === 4 ? "active" : ""}
                            onClick={() => setTab(4)}
                          >
                            Special instruction
                          </NavLink>
                        </NavItem>
                        <NavItem className="store-tabItem">
                          <NavLink
                            id="tab1"
                            // ref={containerRef} style={{ overflow: 'auto', height: '300px' }}
                            className={tab === 5 ? "active" : ""}
                            onClick={() => setTab(5)}
                          >
                            Return Policy
                          </NavLink>
                        </NavItem>
                        {/* <NavItem className="store-tabItem">
                  <NavLink
                    className={tab === 4 ? "active" : ""}
                    onClick={() => setTab(4)}
                  >
                    Reviews
                  </NavLink>
                </NavItem> */}
                      </Nav>
                      <TabContent activeTab={tab} className="mt-4">
                        <TabPane tabId={1}>
                          <div className="row">
                            <div className="col-12">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: productDetail.Description,
                                }}
                              ></div>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId={2}>
                          <Product_Details_VendorDetails
                            BusinessDetails={businessDetails}
                            AverageRatingAndReviews={AverageRatingAndReviews}
                          />
                        </TabPane>
                        <TabPane tabId={3}>
                          <ProductDetailRatingAndReviews
                            AverageRatingAndReviews={AverageRatingAndReviews}
                            RatingCounts={RatingCounts}
                            UserReviewsAndRating={UserReviewsAndRating}
                            getProductDetails={getProductDetails}
                          />
                        </TabPane>
                        <TabPane tabId={4}>
                          <ProductInstrunctions
                            SpecialInstruction={
                              productDetail.SpecialInstruction
                            }
                          />
                        </TabPane>
                        {/* <TabPane tabId={5}>
                          <ProductInstrunctions
                          />
                        </TabPane> */}
                        <TabPane tabId={5}>
                          <ProductReturnPolicy
                            ReturnPolicy={productDetail.ReturnPolicy}
                          />
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 col-sm-12 mt-3">
                    <div>
                      <div className="d-flex justify-content-between mb-2">
                        <h6>Delivery Options</h6>
                        <div>
                          <i className="fas fa-info-circle text-secondary"></i>
                        </div>
                      </div>
                      <div className="product-side-box">
                        <div className="row align-items-center">
                          <div className="col-2 text-center">
                            <i
                              className="fas fa-map-marker-alt text-light-grey"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </div>
                          <div className="col-10">
                            <div
                              style={{ fontSize: "13px" }}
                              className="fw-bold"
                            >
                              Shipping Available
                            </div>
                            <div>
                              {productDetail &&
                              productDetail["ShippingGlobal"] === "N" ? (
                                productDetail.ProductCountry
                              ) : (
                                <>
                                  <button
                                    style={{
                                      outline: "none",
                                      border: "none",
                                      textDecoration: "",
                                    }}
                                    onClick={showModal}
                                  >
                                    Global
                                  </button>
                                  <Modal
                                    title="Shipping Available in Following Countries"
                                    open={isModalOpen}
                                    onOk={handleOk}
                                    onCancel={handleCancel}
                                  >
                                    <p>{AllCountries.toString()}</p>
                                  </Modal>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-2 text-center">
                            <i
                              className="fal fa-city text-light-grey"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </div>
                          <div className="col-10">
                            <div
                              style={{ fontSize: "13px" }}
                              className="fw-bold"
                            >
                              Product Based City
                            </div>
                            <div>
                              {productDetail["ProductCity"] &&
                                productDetail["ProductCity"]}
                              ,{" "}
                              {productDetail["ProductCountry"] &&
                                productDetail["ProductCountry"]}
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-2 text-center">
                            <i
                              className="fas fa-home text-light-grey"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </div>
                          <div className="col-10">
                            <div
                              style={{ fontSize: "13px" }}
                              className="fw-bold"
                            >
                              Domestic Delivery
                            </div>
                            <div>5-7 working days</div>
                          </div>
                        </div>

                        {productDetail["ExpressDelivery"] === "Y" && (
                          <div className="row align-items-center">
                            <div className="col-2 text-center">
                              <i
                                className="fa fa-check"
                                style={{ fontSize: "18px", color: "lightgray" }}
                              ></i>
                            </div>
                            <div className="col-10">
                              <div
                                style={{ fontSize: "13px" }}
                                className="fw-bold"
                              >
                                Express Delivery
                              </div>
                              <div>
                                On Day Delivery. This service is only eligible
                                for delivery inside Dhaka and Chittagong, and If
                                you place your order before 12:00 p.m.
                                Bangladesh Time
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="row align-items-center">
                          <div className="col-2 text-center">
                            <i
                              className="fas fa-globe text-light-grey"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </div>
                          <div className="col-10">
                            <div
                              style={{ fontSize: "13px" }}
                              className="fw-bold"
                            >
                              Global Shipping
                            </div>
                            <div>15-30 days</div>
                          </div>
                        </div>
                        {/* {productDetail &&
                          productDetail["ShippingAvailable"] === "N" && (
                            <div className="row align-items-center">
                              <div className="col-2 text-center">
                                <i
                                  className="fas fa-truck text-light-grey"
                                  style={{ fontSize: "18px" }}
                                ></i>
                              </div>

                              <div className="col-10">
                                <div style={{ fontSize: "13px" }}>
                                  Product City
                                </div>
                                <div >
                                  {productDetail.ProductCity}
                                </div>
                              </div>
                            </div>
                          )} */}
                      </div>
                    </div>
                    <div className="mt-5">
                      <div className="d-flex justify-content-between mb-2">
                        <h6>Return & Warranty</h6>
                        <div>
                          <i className="fas fa-info-circle text-secondary"></i>
                        </div>
                      </div>
                      <div className="product-side-box">
                        <div className="row">
                          <div className="col-3 text-center">
                            <i
                              className="fas fa-truck text-default"
                              style={{
                                fontSize: "20px",
                                verticalAlign: "-webkit-baseline-middle",
                              }}
                            ></i>
                          </div>
                          <div className="col-9">
                            <div style={{ fontSize: "13px" }}>
                              {/* <span style={{ cursor: 'pointer' }} className={tab === 5 ? "active" : ""}
                                onClick={() => {

                                  setTab(5)
                                  // scrollToTab(tabRef1)

                                }}>
                                Shipping & Returns
                              </span> */}

                              <ScrollLink
                                to="tab1"
                                smooth={true}
                                duration={100}
                              >
                                <span
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                  className={tab === 5 ? "active" : ""}
                                  onClick={() => {
                                    setTab(5);
                                  }}
                                >
                                  Shipping & Returns
                                </span>
                              </ScrollLink>
                            </div>
                            <div>For all orders from anywhere</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-3 text-center">
                            <i
                              className="fas fa-cash-register text-default"
                              style={{
                                fontSize: "20px",
                                verticalAlign: "-webkit-baseline-middle",
                              }}
                            ></i>
                          </div>
                          <div className="col-9">
                            <div
                              style={{ fontSize: "13px" }}
                              className="fw-bold"
                            >
                              Secure Payment
                            </div>
                            <div>We ensure secure payment</div>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-3 text-center">
                            <i
                              className="fas fa-dollar-sign text-default"
                              style={{
                                fontSize: "20px",
                                verticalAlign: "-webkit-baseline-middle",
                              }}
                            ></i>
                          </div>
                          <div className="col-9">
                            <div style={{ fontSize: "13px" }}>
                              Money Back Guarantee
                            </div>
                            <div >
                              Any back within 30 days
                            </div>
                          </div>
                        </div> */}
                        <div className="row">
                          <div className="col-3 text-center">
                            <i
                              className="fas fa-headset text-default"
                              style={{
                                fontSize: "20px",
                                verticalAlign: "-webkit-baseline-middle",
                              }}
                            ></i>
                          </div>
                          <div className="col-9">
                            <div
                              style={{ fontSize: "13px" }}
                              className="fw-bold"
                            >
                              Customer Support
                            </div>
                            <div>Call or email us 24/7</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5">
                      <div className="d-flex justify-content-between mb-2">
                        <h6>
                          Seller :{" "}
                          {businessDetails && businessDetails.CompanyName}
                        </h6>
                        {/* <div>
                  <i className="fas fa-info-circle text-secondary"></i>
                </div> */}
                      </div>
                      <div className="product-side-box">
                        <div className="d-flex justify-content-between p-2">
                          <div className="fw-bold">Seller Rating</div>
                          <div>
                            {" "}
                            {businessDetails &&
                              parseFloat(businessDetails.VendorRating).toFixed(
                                2
                              )}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between  p-2">
                          <div className="fw-bold">Ship on time</div>
                          <div> 95%</div>
                        </div>
                        <div className="d-flex justify-content-between  p-2">
                          <div className="fw-bold">Chat Response Rate</div>
                          <div> 97%</div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn btn-light text-default btn-block w-100 mt-2"
                      onClick={() =>
                        history.push(`/store/${businessDetails.CompanyName}`)
                      }
                    >
                      Go to store
                    </button>
                    {/* {JSON.stringify(businessDetails.CompanyName)} */}
                  </div>
                </div>
              </>
            )}
            <div className="mt-5">
              <TopRatedProducts />
            </div>
            <div className="mt-5">
              <Trending loc="product" />
            </div>
          </>
        </div>
      )}
      <NewsLetter />
      <Footer />
    </>
  );
}
export default ProductDetails;
