import routes from "./routes";
import "./assets/css/sidebar.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/dash-counter-card.css";
import "./assets/css/support-chat.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/invoice.css";
import { Route, useLocation } from "react-router-dom";
import axios from "axios";

import Loading from "./Utils/Loading";

import ProtectedRoute from "./Helpers/ProtectedRoute";
import { MODAL_CONTEXT_PROVIDER } from "./Components/Contexts/ModalContext";
import { useState } from "react";
import { useEffect } from "react";
import { CurrentUser } from "./Helpers/Auth";
import { Get_Wishlist_Action } from "./Actions/WishlistActions";
import { useDispatch, useSelector } from "react-redux";
import UpdateUserBrowsingOnLogin from "./Helpers/UpdateUserBrowsingOnLogin";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import BanglaBazarApi from "./Components/Api/BanglaBazarApi";

import Endpoint from "./Utils/Endpoint";
import firetoast from "./Helpers/FireToast";
import {
  getCategoryAndSubCategory,
  getDepartmentCategoryAndSubCategory,
  getCurrentIpv4,
  getLandingPageData,
} from "./Actions/LandingActions";

// import 'antd/dist/antd.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isCountryLoaded, setIsCountryLoaded] = useState(false);
  const [signin, setSignin] = useState(false);
  const [signup, setSignup] = useState(false);
  const [phoneVerify, setPhoneVerify] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const [EmailToBeVerified, setEmailToBeVerified] = useState("");
  const [PhoneNumberToBeVerified, setPhoneNumberToBeVerified] = useState("");
  const [otpRedirectTo, setOtpRedirectTo] = useState(null);
  const dispatch = useDispatch();
  // const location = useLocation();

  const state = useSelector((state) => state);

  async function getUserCountry() {
    try {
      const response = await axios.get("https://geolocation-db.com/json/");
      let country = response.data.country_name.toLowerCase();

      localStorage.setItem("country", country);
      console.log("selectedCountryName", country);

      //   var encodedData = localStorage.getItem("allCountriesList");
      //  // var decodedData = window.atob(encodedData);
      //   var decryptedCountriesData = JSON.parse(encodedData);
      //   console.log("decryptedCountriesData",decryptedCountriesData);

      // Find the country in the decrypted array
      // var foundCountry = decryptedCountriesData.filter(countryData => countryData.Country.toLowerCase()=== country);
      // console.log("foundCountry",foundCountry)

      // Default values for region, currency, and iso
      let region = "United States";
      let currency = "USD";
      let iso = "US";
      let currentCountry = "United States";
      let dc = 226;

      // Set region, currency, and iso based on the fetched country
      if (country === "bangladesh") {
        region = "Bangladesh";
        currency = "BDT";
        iso = "bd";
        currentCountry = "bangladesh";
        dc = 16;
      } else if (country === "pakistan") {
        region = "United States";
        currency = "PKR";
        iso = "pk";
        currentCountry = "pakistan";
        dc = 159;
      } else if (country === "australia") {
        region = "Australia";
        currency = "AUD"; // Corrected currency code for Australia
        iso = "au";
        currentCountry = "australia";
        dc = 11;
      } else if (country === "canada") {
        region = "Canada";
        currency = "CAD";
        iso = "ca";
        currentCountry = "canada";
        dc = 37;
      } else {
        // Default setting for any other country
        region = "United States";
        currency = "USD";
        iso = "us";
        currentCountry = "United States";
        dc = 226;
      }

      // // Find the country in the decrypted array
      // var foundCountry = decryptedCountriesData.find(countryData => countryData.Country.toLowerCase() === selectedCountryName.toLowerCase());

      // if (foundCountry) {
      //     // Set details from the found country
      //     country = foundCountry.Country;
      //     dc = foundCountry.CountryID;
      //     currency = foundCountry.CurrencyCode;
      //     iso = foundCountry.ISO2;
      // } else {
      //     // Default values if country is not found
      //     country = "United States";
      //     dc = 226; // Assuming 226 is the ID for the United States
      //     currency = "USD";
      //     iso = "US";
      // }

      // // Now you can use country, countryID, currencyCode, iosCountryCode, and iso2 as needed
      // console.log("Country: " + country);
      // console.log("ISO2: " + iso);

      // if (foundCountry) {
      //     // Set details from the found country
      //     region = foundCountry.Country;
      //     currency = foundCountry.CurrencyCode;
      //     iso = foundCountry.ISO2;
      //     dc = foundCountry.CountryID;
      //     currentCountry = foundCountry.Country; // Assuming currentCountry should match the found country's name
      // }
      // else{
      //    region = "United States";
      //    currency = "USD";
      //    iso = "US";
      //    currentCountry = 'United States';
      //    dc = 226;
      // }
      // // Now you can use region, currency, iso, currentCountry, and dc as needed
      // console.log("Country: " + region);
      // console.log("ISO2: " + iso);

      if (!localStorage.getItem("region")) {
        localStorage.setItem("region", region);
      }
      if (!localStorage.getItem("currency")) {
        localStorage.setItem("currency", currency);
      }
      if (!localStorage.getItem("iso")) {
        localStorage.setItem("iso", iso);
      }

      // Setting loading and country loaded states
      setIsLoading(false);
      setIsCountryLoaded(true);
    } catch (error) {
      console.error(error);
      // Handle error, perhaps set loading state to false
      setIsLoading(false);
    }
  }

  // Call getUserCountry initially
  useEffect(() => {
    getUserCountry();
  }, []);

  //second UseEffect
  useEffect(async () => {
    if (isCountryLoaded) {
      // dispatch(getDepartmentCategoryAndSubCategory());
      dispatch(getCategoryAndSubCategory());
      dispatch(getCurrentIpv4());

      //if (CurrentUser) dispatch(Get_Wishlist_Action());
      var isUser = localStorage.getItem("user");
      if (state.currentIPv4.IP["country_name"]) {
        {
          console.log(
            state.currentIPv4.IP["country_name"],
            "state.currentIPv4.IP"
          );
        }
        if (!localStorage.getItem("region")) {
          // localStorage.setItem("region", state.currentIPv4.IP["country_name"]);
          localStorage.setItem("region", "United States");
          window.location.reload();
        }
        if (isUser) {
          dispatch(
            getLandingPageData(
              state.currentIPv4.IP["country_name"],
              CurrentUser.UserID
            )
          );
          UpdateUserBrowsingOnLogin(state.currentIPv4);
        } else {
          if (
            getLandingPageData(
              state.currentIPv4.IP["country_name"] === "Bangladesh"
            )
          )
            dispatch(
              getLandingPageData(state.currentIPv4.IP["country_name"], "")
            );
          else
            dispatch(
              getLandingPageData(state.currentIPv4.IP["country_name"], "1")
            );
        }
      }
      CurrentUser && updateCartItems();
    }
  }, [isCountryLoaded, dispatch, state.currentIPv4.IP["country_name"]]);

  let updateCartItems = async () => {
    var localCart = JSON.parse(localStorage.getItem("uLC"));
    var ProductDetail = [];
    if (localCart && localCart.length > 0) {
      // console.log(localCart);
      for (let i = 0; i < localCart.length; i++) {
        ProductDetail.push(JSON.parse(localCart[i]).ProductDetail[0]);
      }
      var data = {
        SessionID: localStorage.getItem("accessToken"),
        ProductDetail,
      };

      try {
        var response = await BanglaBazarApi.post(
          `${Endpoint}/api/wish-list/addCart`,
          data
        );
        if (response.data.status) {
          setTimeout(() => localStorage.removeItem("uLC"), 2000);
        } else {
          var { message, error } = response.data;
          firetoast(message || error, "default-error");
        }
      } catch (e) {
        firetoast("Something went wrong while updating cart", "default-error");
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loading />
        </div>
      ) : (
        <MODAL_CONTEXT_PROVIDER
          value={{
            signin,
            setSignin,
            signup,
            setSignup,
            phoneVerify,
            setPhoneVerify,
            emailVerify,
            setEmailVerify,
            EmailToBeVerified,
            setEmailToBeVerified,
            PhoneNumberToBeVerified,
            setPhoneNumberToBeVerified,
            otpRedirectTo,
            setOtpRedirectTo,
          }}
        >
          <div className="App">
            {routes.map((route, index) => (
              <div key={index}>
                {route.protect ? (
                  <ProtectedRoute
                    path={route.path}
                    component={route.component}
                    key={index}
                    exact={route.exact}
                    // onUpdate={() => window.scrollTo(0, 0)}
                  />
                ) : (
                  <Route
                    path={route.path}
                    component={route.component}
                    key={index}
                    exact={route.exact}
                    // onUpdate={() => window.scrollTo(0, 0)}
                  />
                )}
              </div>
            ))}
          </div>
        </MODAL_CONTEXT_PROVIDER>
      )}
    </>
  );
}
export default App;
