import { Alert } from "reactstrap";
import { IsPhoneVerified } from "./../../../../Helpers/CheckPhoneVerification";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useState, useEffect } from "react";
import logo from "../../../../assets/images/logo.png";
import Image from "../../../../assets/images/otp-img.png";
import OtpInput from "react-otp-input";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getPhoneNumberStatusPending,
  getPhoneNumberStatusSuccess,
  getPhoneNumberStatusFailed,
} from "../../../../Actions/phoneAction";

import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import firebase from "./../../../../Helpers/Firebase";
import { CurrentUser } from "../../../../Helpers/Auth";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Endpoint from "./../../../../Utils/Endpoint";
import MODAL_CONTEXT from "../../../Contexts/ModalContext";
import { useContext } from "react";
import BanglaBazarApi from "../../../Api/BanglaBazarApi";
import { useRef } from "react";

export function PhoneVerificationAlert() {
  const dispatch = useDispatch();

  const { id } = useParams();

  const otpInputRef = useRef(null);

  useEffect(() => {
    if (otpInputRef && otpInputRef.current) {
      otpInputRef.current.focus();
    }
    console.log(id, "------------------------");
  }, []);

  const { phoneVerify, setPhoneVerify, PhoneNumberToBeVerified } =
    useContext(MODAL_CONTEXT);

  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toggle = () => {
    setPhoneVerify(!phoneVerify);
  };
  const [countDownTime, setCountDownTime] = useState("");
  const [showResend, setShowResend] = useState(false);

  async function timer(remaining) {
    var m = Math.floor(remaining / 60);
    var s = remaining % 60;

    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;

    setCountDownTime(m + ":" + s);
    remaining -= 1;

    if (remaining >= 0) {
      setTimeout(function () {
        timer(remaining);
      }, 1000);
      return;
    }
    if (remaining < 1) {
      setShowResend(true);
    }
  }
  // console.log(firebase);
  useEffect(() => {
    if (phoneVerify) {
      const container = document.getElementById("main");
      const element = document.createElement("div");
      element.setAttribute("id", "sign-in-button");
      container.appendChild(element);
      configureCaptcha();
    }

    if (!phoneVerify) {
      const elmnt = document.getElementById("sign-in-button");
      if (elmnt) {
        elmnt.remove();
      }
    }
  }, [phoneVerify]);

  var configureCaptcha = () => {
    console.log("Captcha Function");
    const auth = getAuth();
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log(response);
        },
        defaultCountry: "PK",
      },
      auth
    );
    toast.info("OTP is sent to your registered mobile number!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    onSignInSubmit();
  };

  var onSignInSubmit = () => {
    console.log("SMS Method");
    var phoneNumber = null;
    const auth = getAuth();
    if (PhoneNumberToBeVerified) {
      phoneNumber = PhoneNumberToBeVerified.includes("+")
        ? PhoneNumberToBeVerified
        : "+" + PhoneNumberToBeVerified;
    } else {
      phoneNumber = CurrentUser.PhoneNumber.includes("+")
        ? CurrentUser.PhoneNumber
        : "+" + CurrentUser.PhoneNumber;
    }
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        console.log("confirmation");
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      })
      .catch((error) => {
        console.log(error);
        setPhoneVerify(!phoneVerify);
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  let verifyOtp = async () => {
    var code = otp;
    window.confirmationResult
      .confirm(code)
      .then(async (result) => {
        toast.info("OTP Successfully verified", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(PhoneNumberToBeVerified, "verifynmb");
        localStorage.setItem("phoneVerify", "true");
        // Explicitly close the modal and reset OTP
        setPhoneVerify(false); // Explicitly close the modal
        setOtp(""); // Reset the OTP input field

        const isBan = localStorage.getItem("isBan");

        if (isBan) {
          localStorage.setItem("phoneVerify", true);
          localStorage.setItem(
            "PhoneNumberToBeVerified",
            PhoneNumberToBeVerified
          );
          try {
            var form = new URLSearchParams();
            if (id) {
              form.append("UserID", id);
            } else {
              console.log("ELSE");
              form.append("UserID", CurrentUser.UserID);
            }
            form.append("phoneNumber", PhoneNumberToBeVerified);
            await BanglaBazarApi.put(
              Endpoint + "/api/user/update-number",
              form
            );
            var temp = CurrentUser;
            temp.PhoneVerified = "Y";
            var phoneFinal = CurrentUser;
            phoneFinal.PhoneNumber = PhoneNumberToBeVerified.replace(
              /^\+880/,
              ""
            );

            localStorage.setItem("user", JSON.stringify(temp));
            dispatch(getPhoneNumberStatusSuccess());

            setPhoneVerify(!phoneVerify);
          } catch (e) {
            console.log(e);
          }
        } else {
          try {
            var form = new URLSearchParams();
            if (id) {
              form.append("UserID", id);
            } else {
              console.log("ELSE");
              form.append("UserID", CurrentUser.UserID);
            }
            form.append("phoneNumber", PhoneNumberToBeVerified);
            await BanglaBazarApi.put(
              Endpoint + "/api/user/update-number",
              form
            );
            var temp = CurrentUser;
            temp.PhoneVerified = "Y";
            var phoneFinal = CurrentUser;
            phoneFinal.PhoneNumber = PhoneNumberToBeVerified.replace(
              /^\+880/,
              ""
            );

            localStorage.setItem("user", JSON.stringify(temp));

            setPhoneVerify(!phoneVerify);
          } catch (e) {
            console.log(e);
          }
        }

        const getIsBan = localStorage.getItem("isBan");
        // if (!getIsBan) {
        //   setTimeout(() => {
        //     window.location.reload();
        //   }, 1000);
        // }

        console.log(result);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <div className="container mt-3" id="main">
      {/* <ToastContainer /> */}
      {/* <div id="sign-in-button"></div> */}
      {/* <Alert color="success" style={{ fontSize: "13px" }}>
        It seems you haven't verified your phone number ,please verify your
        phone.{"  "}
        <Link
          to="#"
          onClick={() => {
            toggle();
         
          }}
        >
          Proceed <i className="fas fa-long-arrow-alt-right"></i>
        </Link>
      </Alert> */}
      <Modal
        isOpen={phoneVerify}
        toggle={() => {
          toggle();
        }}
        backdrop="static"
        size="lg"
      >
        <ModalHeader
          toggle={() => {
            toggle();
          }}
        ></ModalHeader>
        <ModalBody>
          {" "}
          <div className="row m-auto mb-2">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 m-auto">
              <div className="text-center mt-5">
                <img src={logo} className="logo" />
              </div>
              <div className="text-center mt-5">
                <img
                  src={Image}
                  className="img-otp"
                  style={{ height: "140px" }}
                />
                <h2 className="ftw-400 mt-4">
                  Please Verify Your Phone Number
                </h2>

                <div className="otp-input text-center row m-0">
                  <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 m-auto">
                    <p className="default-p">
                      Enter the six digit code we sent to your phone number to
                      verify your new BanglaBazar account.
                    </p>
                    <OtpInput
                      // ref={otpInputRef}

                      shouldAutoFocus={true}
                      value={otp}
                      onChange={(e) => setOtp(e)}
                      numInputs={6}
                      separator={<span className="text-white">{""}</span>}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-xl-7 col-lg-7 col-md-7 col-sm-8 col-xs-12 m-auto">
                    <button
                      className="btn btn-block btn-default w-100 border-radius-25"
                      style={{ fontSize: "20px" }}
                      onClick={() => verifyOtp()}
                    >
                      Verify
                    </button>
                  </div>
                </div>
                <div className="mt-5 mb-5">
                  <div className="col-9 m-auto">
                    <p className="default-p">
                      {" "}
                      {showResend ? (
                        <>
                          Haven't received any code ?
                          <Link className="text-default td-none" to="#">
                            Resend a new code
                          </Link>
                        </>
                      ) : (
                        <> It may take a minute to receive your code.</>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
